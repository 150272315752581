import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';
import { NIKKEOutpostData } from '../../../modules/nikke/data/outpost-data';

const NikkeGuidesOutpostOutputPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Outpost output</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_output.png"
            alt="Outpost output"
          />
        </div>
        <div className="page-details">
          <h1>Outpost output</h1>
          <h2>How much resources your Outpost is producing every minute.</h2>
          <p>
            Last updated: <strong>29/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Outpost Defense" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_calc.jpg"
          alt="Bonds"
        />
        <p>
          Your Outpost is constantly producing resources, even if the game is
          closed. So when you login after waking up, you can get free Credits,
          Battle Data, Core Dust, commander experience and equipment. Keep in
          mind that it{' '}
          <strong>
            takes 12 hours for the Outpost Defense storage to fill
          </strong>
          , so try to to log in 2 times a day to not lose any resources.
        </p>
        <p>
          Also, you need to{' '}
          <strong>
            reach level 73 of the Outpost Defense building to have a chance to
            get Tier 7 equipment
          </strong>
          . You can upgrade the building by clearing Main Story stages (every 5
          stages cleared = 1 level).
        </p>
        <SectionHeader title="Outpost output" />
        <p>
          <strong>Important!</strong> The numbers visible in the table below are
          the base values and the bonuses you earn in the Tactical Academy
          aren't included.
        </p>
        <Table striped bordered responsive className="table-small">
          <thead>
            <tr>
              <th title="Field #1">Outpost level</th>
              <th title="Field #2">Credit/M</th>
              <th title="Field #3">Credit/H</th>
              <th title="Field #4">Battle data/M</th>
              <th title="Field #5">Battle Data/H</th>
              <th title="Field #6">Commander Exp/M</th>
              <th title="Field #7">Commander Exp/H</th>
              <th title="Field #8">Core Dust/M</th>
              <th title="Field #9">Core Dust/H</th>
              <th title="Field #10">Core Dust/H (maxed Aca)</th>
            </tr>
          </thead>
          <tbody>
            {NIKKEOutpostData.map((outpost, index) => {
              return (
                <tr key={index}>
                  <td>
                    <strong>{outpost.level.toFixed(0)}</strong>
                  </td>
                  <td>{outpost.credit_m.toFixed(0)}</td>
                  <td>{outpost.credit_h.toFixed(0)}</td>
                  <td>{outpost.battle_m.toFixed(0)}</td>
                  <td>{outpost.battle_h.toFixed(0)}</td>
                  <td>{outpost.exp_m.toFixed(0)}</td>
                  <td>{outpost.exp_h.toFixed(0)}</td>
                  <td>{outpost.dust_m.toFixed(2)}</td>
                  <td>{outpost.dust_h.toFixed(2)}</td>
                  <td>{outpost.core_h.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesOutpostOutputPage;

export const Head: React.FC = () => (
  <Seo
    title="Outpost output | NIKKE | Prydwen Institute"
    description="How much resources your Outpost is producing every minute."
  />
);
