export const NIKKEOutpostData = [
  {
    level: 1,
    credit_m: 36,
    credit_h: 2160,
    battle_m: 73.9998,
    battle_h: 4439.988,
    exp_m: 30.9999,
    exp_h: 1859.994,
    dust_m: 0.2326,
    dust_h: 13.956,
    core_h: 20.2362
  },
  {
    level: 2,
    credit_m: 40.9998,
    credit_h: 2459.988,
    battle_m: 105.9999,
    battle_h: 6359.994,
    exp_m: 33,
    exp_h: 1980,
    dust_m: 0.2521,
    dust_h: 15.126,
    core_h: 21.9327
  },
  {
    level: 3,
    credit_m: 46.9998,
    credit_h: 2819.988,
    battle_m: 141.9999,
    battle_h: 8519.994,
    exp_m: 36,
    exp_h: 2160,
    dust_m: 0.2688,
    dust_h: 16.128,
    core_h: 23.3856
  },
  {
    level: 4,
    credit_m: 51.9999,
    credit_h: 3119.994,
    battle_m: 181.9998,
    battle_h: 10919.988,
    exp_m: 37.9998,
    exp_h: 2279.988,
    dust_m: 0.2836,
    dust_h: 17.016,
    core_h: 24.6732
  },
  {
    level: 5,
    credit_m: 57.9999,
    credit_h: 3479.994,
    battle_m: 225,
    battle_h: 13500,
    exp_m: 40.9998,
    exp_h: 2459.988,
    dust_m: 0.2969,
    dust_h: 17.814,
    core_h: 25.8303
  },
  {
    level: 6,
    credit_m: 63,
    credit_h: 3780,
    battle_m: 259.9998,
    battle_h: 15599.988,
    exp_m: 42.9999,
    exp_h: 2579.994,
    dust_m: 0.3061,
    dust_h: 18.366,
    core_h: 26.6307
  },
  {
    level: 7,
    credit_m: 69,
    credit_h: 4140,
    battle_m: 309.9999,
    battle_h: 18599.994,
    exp_m: 45.9999,
    exp_h: 2759.994,
    dust_m: 0.3175,
    dust_h: 19.05,
    core_h: 27.6225
  },
  {
    level: 8,
    credit_m: 75,
    credit_h: 4500,
    battle_m: 363,
    battle_h: 21780,
    exp_m: 48,
    exp_h: 2880,
    dust_m: 0.3281,
    dust_h: 19.686,
    core_h: 28.5447
  },
  {
    level: 9,
    credit_m: 78,
    credit_h: 4680,
    battle_m: 390,
    battle_h: 23400,
    exp_m: 51,
    exp_h: 3060,
    dust_m: 0.3332,
    dust_h: 19.992,
    core_h: 28.9884
  },
  {
    level: 10,
    credit_m: 81.9999,
    credit_h: 4919.994,
    battle_m: 432.9999,
    battle_h: 25979.994,
    exp_m: 54,
    exp_h: 3240,
    dust_m: 0.3404,
    dust_h: 20.424,
    core_h: 29.6148
  },
  {
    level: 11,
    credit_m: 84.9999,
    credit_h: 5099.994,
    battle_m: 462.9999,
    battle_h: 27779.994,
    exp_m: 57,
    exp_h: 3420,
    dust_m: 0.3451,
    dust_h: 20.706,
    core_h: 30.0237
  },
  {
    level: 12,
    credit_m: 88.9998,
    credit_h: 5339.988,
    battle_m: 492.9999,
    battle_h: 29579.994,
    exp_m: 60,
    exp_h: 3600,
    dust_m: 0.3496,
    dust_h: 20.976,
    core_h: 30.4152
  },
  {
    level: 13,
    credit_m: 90,
    credit_h: 5400,
    battle_m: 507.9999,
    battle_h: 30479.994,
    exp_m: 63,
    exp_h: 3780,
    dust_m: 0.3519,
    dust_h: 21.114,
    core_h: 30.6153
  },
  {
    level: 14,
    credit_m: 93,
    credit_h: 5580,
    battle_m: 540,
    battle_h: 32400,
    exp_m: 64.9998,
    exp_h: 3899.988,
    dust_m: 0.3562,
    dust_h: 21.372,
    core_h: 30.9894
  },
  {
    level: 15,
    credit_m: 96,
    credit_h: 5760,
    battle_m: 571.9998,
    battle_h: 34319.988,
    exp_m: 67.9998,
    exp_h: 4079.988,
    dust_m: 0.3605,
    dust_h: 21.63,
    core_h: 31.3635
  },
  {
    level: 16,
    credit_m: 97.9998,
    credit_h: 5879.988,
    battle_m: 588,
    battle_h: 35280,
    exp_m: 70.9998,
    exp_h: 4259.988,
    dust_m: 0.3625,
    dust_h: 21.75,
    core_h: 31.5375
  },
  {
    level: 17,
    credit_m: 100.9998,
    credit_h: 6059.988,
    battle_m: 621,
    battle_h: 37260,
    exp_m: 73.9998,
    exp_h: 4439.988,
    dust_m: 0.3666,
    dust_h: 21.996,
    core_h: 31.8942
  },
  {
    level: 18,
    credit_m: 102.9999,
    credit_h: 6179.994,
    battle_m: 636.9999,
    battle_h: 38219.994,
    exp_m: 78,
    exp_h: 4680,
    dust_m: 0.3686,
    dust_h: 22.116,
    core_h: 32.0682
  },
  {
    level: 19,
    credit_m: 105.9999,
    credit_h: 6359.994,
    battle_m: 670.9998,
    battle_h: 40259.988,
    exp_m: 81,
    exp_h: 4860,
    dust_m: 0.3726,
    dust_h: 22.356,
    core_h: 32.4162
  },
  {
    level: 20,
    credit_m: 108.9999,
    credit_h: 6539.994,
    battle_m: 705.9999,
    battle_h: 42359.994,
    exp_m: 84,
    exp_h: 5040,
    dust_m: 0.3765,
    dust_h: 22.59,
    core_h: 32.7555
  },
  {
    level: 21,
    credit_m: 111,
    credit_h: 6660,
    battle_m: 723.9999,
    battle_h: 43439.994,
    exp_m: 87,
    exp_h: 5220,
    dust_m: 0.3784,
    dust_h: 22.704,
    core_h: 32.9208
  },
  {
    level: 22,
    credit_m: 111.9999,
    credit_h: 6719.994,
    battle_m: 741,
    battle_h: 44460,
    exp_m: 90,
    exp_h: 5400,
    dust_m: 0.3803,
    dust_h: 22.818,
    core_h: 33.0861
  },
  {
    level: 23,
    credit_m: 115.9998,
    credit_h: 6959.988,
    battle_m: 777,
    battle_h: 46620,
    exp_m: 93,
    exp_h: 5580,
    dust_m: 0.384,
    dust_h: 23.04,
    core_h: 33.408
  },
  {
    level: 24,
    credit_m: 117,
    credit_h: 7020,
    battle_m: 795,
    battle_h: 47700,
    exp_m: 96,
    exp_h: 5760,
    dust_m: 0.3858,
    dust_h: 23.148,
    core_h: 33.5646
  },
  {
    level: 25,
    credit_m: 120,
    credit_h: 7200,
    battle_m: 831.9999,
    battle_h: 49919.994,
    exp_m: 99.9999,
    exp_h: 5999.994,
    dust_m: 0.3894,
    dust_h: 23.364,
    core_h: 33.8778
  },
  {
    level: 26,
    credit_m: 121.9998,
    credit_h: 7319.988,
    battle_m: 850.9998,
    battle_h: 51059.988,
    exp_m: 102.9999,
    exp_h: 6179.994,
    dust_m: 0.3912,
    dust_h: 23.472,
    core_h: 34.0344
  },
  {
    level: 27,
    credit_m: 124.9998,
    credit_h: 7499.988,
    battle_m: 888.9999,
    battle_h: 53339.994,
    exp_m: 105.9999,
    exp_h: 6359.994,
    dust_m: 0.3946,
    dust_h: 23.676,
    core_h: 34.3302
  },
  {
    level: 28,
    credit_m: 129,
    credit_h: 7740,
    battle_m: 927,
    battle_h: 55620,
    exp_m: 108.9999,
    exp_h: 6539.994,
    dust_m: 0.3981,
    dust_h: 23.886,
    core_h: 34.6347
  },
  {
    level: 29,
    credit_m: 129.9999,
    credit_h: 7799.994,
    battle_m: 945.9999,
    battle_h: 56759.994,
    exp_m: 112.9998,
    exp_h: 6779.988,
    dust_m: 0.3998,
    dust_h: 23.988,
    core_h: 34.7826
  },
  {
    level: 30,
    credit_m: 133.9998,
    credit_h: 8039.988,
    battle_m: 985.9998,
    battle_h: 59159.988,
    exp_m: 115.9998,
    exp_h: 6959.988,
    dust_m: 0.4031,
    dust_h: 24.186,
    core_h: 35.0697
  },
  {
    level: 31,
    credit_m: 136.9998,
    credit_h: 8219.988,
    battle_m: 1025,
    battle_h: 61500,
    exp_m: 118.9998,
    exp_h: 7139.988,
    dust_m: 0.4064,
    dust_h: 24.384,
    core_h: 35.3568
  },
  {
    level: 32,
    credit_m: 139.9998,
    credit_h: 8399.988,
    battle_m: 1066,
    battle_h: 63960,
    exp_m: 123,
    exp_h: 7380,
    dust_m: 0.4096,
    dust_h: 24.576,
    core_h: 35.6352
  },
  {
    level: 33,
    credit_m: 141.9999,
    credit_h: 8519.994,
    battle_m: 1086,
    battle_h: 65160,
    exp_m: 126,
    exp_h: 7560,
    dust_m: 0.4112,
    dust_h: 24.672,
    core_h: 35.7744
  },
  {
    level: 34,
    credit_m: 144.9999,
    credit_h: 8699.994,
    battle_m: 1128,
    battle_h: 67680,
    exp_m: 129,
    exp_h: 7740,
    dust_m: 0.4143,
    dust_h: 24.858,
    core_h: 36.0441
  },
  {
    level: 35,
    credit_m: 147,
    credit_h: 8820,
    battle_m: 1148,
    battle_h: 68880,
    exp_m: 132.9999,
    exp_h: 7979.994,
    dust_m: 0.4159,
    dust_h: 24.954,
    core_h: 36.1833
  },
  {
    level: 36,
    credit_m: 150,
    credit_h: 9000,
    battle_m: 1191,
    battle_h: 71460,
    exp_m: 135.9999,
    exp_h: 8159.994,
    dust_m: 0.419,
    dust_h: 25.14,
    core_h: 36.453
  },
  {
    level: 37,
    credit_m: 153.9999,
    credit_h: 9239.994,
    battle_m: 1233,
    battle_h: 73980,
    exp_m: 139.9998,
    exp_h: 8399.988,
    dust_m: 0.422,
    dust_h: 25.32,
    core_h: 36.714
  },
  {
    level: 38,
    credit_m: 154.9998,
    credit_h: 9299.988,
    battle_m: 1255,
    battle_h: 75300,
    exp_m: 142.9998,
    exp_h: 8579.988,
    dust_m: 0.4235,
    dust_h: 25.41,
    core_h: 36.8445
  },
  {
    level: 39,
    credit_m: 159,
    credit_h: 9540,
    battle_m: 1298,
    battle_h: 77880,
    exp_m: 145.9998,
    exp_h: 8759.988,
    dust_m: 0.4264,
    dust_h: 25.584,
    core_h: 37.0968
  },
  {
    level: 40,
    credit_m: 159.9999,
    credit_h: 9599.994,
    battle_m: 1320,
    battle_h: 79200,
    exp_m: 150,
    exp_h: 9000,
    dust_m: 0.4279,
    dust_h: 25.674,
    core_h: 37.2273
  },
  {
    level: 41,
    credit_m: 162,
    credit_h: 9720,
    battle_m: 1342,
    battle_h: 80520,
    exp_m: 153,
    exp_h: 9180,
    dust_m: 0.4294,
    dust_h: 25.764,
    core_h: 37.3578
  },
  {
    level: 42,
    credit_m: 165,
    credit_h: 9900,
    battle_m: 1387,
    battle_h: 83220,
    exp_m: 156.9999,
    exp_h: 9419.994,
    dust_m: 0.4322,
    dust_h: 25.932,
    core_h: 37.6014
  },
  {
    level: 43,
    credit_m: 166.9998,
    credit_h: 10019.988,
    battle_m: 1410,
    battle_h: 84600,
    exp_m: 159.9999,
    exp_h: 9599.994,
    dust_m: 0.4337,
    dust_h: 26.022,
    core_h: 37.7319
  },
  {
    level: 44,
    credit_m: 171,
    credit_h: 10260,
    battle_m: 1455,
    battle_h: 87300,
    exp_m: 163.9998,
    exp_h: 9839.988,
    dust_m: 0.4365,
    dust_h: 26.19,
    core_h: 37.9755
  },
  {
    level: 45,
    credit_m: 174,
    credit_h: 10440,
    battle_m: 1501,
    battle_h: 90060,
    exp_m: 166.9998,
    exp_h: 10019.988,
    dust_m: 0.4392,
    dust_h: 26.352,
    core_h: 38.2104
  },
  {
    level: 46,
    credit_m: 177,
    credit_h: 10620,
    battle_m: 1547,
    battle_h: 92820,
    exp_m: 171,
    exp_h: 10260,
    dust_m: 0.442,
    dust_h: 26.52,
    core_h: 38.454
  },
  {
    level: 47,
    credit_m: 178.9998,
    credit_h: 10739.988,
    battle_m: 1571,
    battle_h: 94260,
    exp_m: 174,
    exp_h: 10440,
    dust_m: 0.4433,
    dust_h: 26.598,
    core_h: 38.5671
  },
  {
    level: 48,
    credit_m: 183,
    credit_h: 10980,
    battle_m: 1618,
    battle_h: 97080,
    exp_m: 177.9999,
    exp_h: 10679.994,
    dust_m: 0.446,
    dust_h: 26.76,
    core_h: 38.802
  },
  {
    level: 49,
    credit_m: 186,
    credit_h: 11160,
    battle_m: 1666,
    battle_h: 99960,
    exp_m: 180.9999,
    exp_h: 10859.994,
    dust_m: 0.4487,
    dust_h: 26.922,
    core_h: 39.0369
  },
  {
    level: 50,
    credit_m: 187.9998,
    credit_h: 11279.988,
    battle_m: 1690,
    battle_h: 101400,
    exp_m: 184.9998,
    exp_h: 11099.988,
    dust_m: 0.45,
    dust_h: 27,
    core_h: 39.15
  },
  {
    level: 51,
    credit_m: 190.9998,
    credit_h: 11459.988,
    battle_m: 1738,
    battle_h: 104280,
    exp_m: 189,
    exp_h: 11340,
    dust_m: 0.4526,
    dust_h: 27.156,
    core_h: 39.3762
  },
  {
    level: 52,
    credit_m: 195,
    credit_h: 11700,
    battle_m: 1787,
    battle_h: 107220,
    exp_m: 192,
    exp_h: 11520,
    dust_m: 0.4552,
    dust_h: 27.312,
    core_h: 39.6024
  },
  {
    level: 53,
    credit_m: 195.9999,
    credit_h: 11759.994,
    battle_m: 1812,
    battle_h: 108720,
    exp_m: 195.9999,
    exp_h: 11759.994,
    dust_m: 0.4564,
    dust_h: 27.384,
    core_h: 39.7068
  },
  {
    level: 54,
    credit_m: 199.9998,
    credit_h: 11999.988,
    battle_m: 1862,
    battle_h: 111720,
    exp_m: 198.9999,
    exp_h: 11939.994,
    dust_m: 0.459,
    dust_h: 27.54,
    core_h: 39.933
  },
  {
    level: 55,
    credit_m: 201.9999,
    credit_h: 12119.994,
    battle_m: 1887,
    battle_h: 113220,
    exp_m: 202.9998,
    exp_h: 12179.988,
    dust_m: 0.4602,
    dust_h: 27.612,
    core_h: 40.0374
  },
  {
    level: 56,
    credit_m: 204.9999,
    credit_h: 12299.994,
    battle_m: 1937,
    battle_h: 116220,
    exp_m: 207,
    exp_h: 12420,
    dust_m: 0.4627,
    dust_h: 27.762,
    core_h: 40.2549
  },
  {
    level: 57,
    credit_m: 207,
    credit_h: 12420,
    battle_m: 1963,
    battle_h: 117780,
    exp_m: 210,
    exp_h: 12600,
    dust_m: 0.4639,
    dust_h: 27.834,
    core_h: 40.3593
  },
  {
    level: 58,
    credit_m: 210,
    credit_h: 12600,
    battle_m: 2014,
    battle_h: 120840,
    exp_m: 213.9999,
    exp_h: 12839.994,
    dust_m: 0.4664,
    dust_h: 27.984,
    core_h: 40.5768
  },
  {
    level: 59,
    credit_m: 211.9998,
    credit_h: 12719.988,
    battle_m: 2040,
    battle_h: 122400,
    exp_m: 217.9998,
    exp_h: 13079.988,
    dust_m: 0.4676,
    dust_h: 28.056,
    core_h: 40.6812
  },
  {
    level: 60,
    credit_m: 213.9999,
    credit_h: 12839.994,
    battle_m: 2066,
    battle_h: 123960,
    exp_m: 220.9998,
    exp_h: 13259.988,
    dust_m: 0.4688,
    dust_h: 28.128,
    core_h: 40.7856
  },
  {
    level: 61,
    credit_m: 216.9999,
    credit_h: 13019.994,
    battle_m: 2118,
    battle_h: 127080,
    exp_m: 225,
    exp_h: 13500,
    dust_m: 0.4712,
    dust_h: 28.272,
    core_h: 40.9944
  },
  {
    level: 62,
    credit_m: 219,
    credit_h: 13140,
    battle_m: 2144,
    battle_h: 128640,
    exp_m: 228.9999,
    exp_h: 13739.994,
    dust_m: 0.4723,
    dust_h: 28.338,
    core_h: 41.0901
  },
  {
    level: 63,
    credit_m: 222.9999,
    credit_h: 13379.994,
    battle_m: 2197,
    battle_h: 131820,
    exp_m: 231.9999,
    exp_h: 13919.994,
    dust_m: 0.4747,
    dust_h: 28.482,
    core_h: 41.2989
  },
  {
    level: 64,
    credit_m: 223.9998,
    credit_h: 13439.988,
    battle_m: 2224,
    battle_h: 133440,
    exp_m: 235.9998,
    exp_h: 14159.988,
    dust_m: 0.4759,
    dust_h: 28.554,
    core_h: 41.4033
  },
  {
    level: 65,
    credit_m: 225.9999,
    credit_h: 13559.994,
    battle_m: 2251,
    battle_h: 135060,
    exp_m: 240,
    exp_h: 14400,
    dust_m: 0.477,
    dust_h: 28.62,
    core_h: 41.499
  },
  {
    level: 66,
    credit_m: 229.9998,
    credit_h: 13799.988,
    battle_m: 2305,
    battle_h: 138300,
    exp_m: 243.9999,
    exp_h: 14639.994,
    dust_m: 0.4793,
    dust_h: 28.758,
    core_h: 41.6991
  },
  {
    level: 67,
    credit_m: 231.9999,
    credit_h: 13919.994,
    battle_m: 2332,
    battle_h: 139920,
    exp_m: 246.9999,
    exp_h: 14819.994,
    dust_m: 0.4805,
    dust_h: 28.83,
    core_h: 41.8035
  },
  {
    level: 68,
    credit_m: 232.9998,
    credit_h: 13979.988,
    battle_m: 2359,
    battle_h: 141540,
    exp_m: 250.9998,
    exp_h: 15059.988,
    dust_m: 0.4816,
    dust_h: 28.896,
    core_h: 41.8992
  },
  {
    level: 69,
    credit_m: 234.9999,
    credit_h: 14099.994,
    battle_m: 2386,
    battle_h: 143160,
    exp_m: 255,
    exp_h: 15300,
    dust_m: 0.4827,
    dust_h: 28.962,
    core_h: 41.9949
  },
  {
    level: 70,
    credit_m: 238.9998,
    credit_h: 14339.988,
    battle_m: 2441,
    battle_h: 146460,
    exp_m: 258.9999,
    exp_h: 15539.994,
    dust_m: 0.485,
    dust_h: 29.1,
    core_h: 42.195
  },
  {
    level: 71,
    credit_m: 240,
    credit_h: 14400,
    battle_m: 2469,
    battle_h: 148140,
    exp_m: 261.9999,
    exp_h: 15719.994,
    dust_m: 0.4861,
    dust_h: 29.166,
    core_h: 42.2907
  },
  {
    level: 72,
    credit_m: 241.9998,
    credit_h: 14519.988,
    battle_m: 2497,
    battle_h: 149820,
    exp_m: 265.9998,
    exp_h: 15959.988,
    dust_m: 0.4872,
    dust_h: 29.232,
    core_h: 42.3864
  },
  {
    level: 73,
    credit_m: 246,
    credit_h: 14760,
    battle_m: 2553,
    battle_h: 153180,
    exp_m: 270,
    exp_h: 16200,
    dust_m: 0.4894,
    dust_h: 29.364,
    core_h: 42.5778
  },
  {
    level: 74,
    credit_m: 249,
    credit_h: 14940,
    battle_m: 2609,
    battle_h: 156540,
    exp_m: 273.9999,
    exp_h: 16439.994,
    dust_m: 0.4916,
    dust_h: 29.496,
    core_h: 42.7692
  },
  {
    level: 75,
    credit_m: 252.9999,
    credit_h: 15179.994,
    battle_m: 2666,
    battle_h: 159960,
    exp_m: 276.9999,
    exp_h: 16619.994,
    dust_m: 0.4937,
    dust_h: 29.622,
    core_h: 42.9519
  },
  {
    level: 76,
    credit_m: 256.9998,
    credit_h: 15419.988,
    battle_m: 2724,
    battle_h: 163440,
    exp_m: 280.9998,
    exp_h: 16859.988,
    dust_m: 0.4959,
    dust_h: 29.754,
    core_h: 43.1433
  },
  {
    level: 77,
    credit_m: 259.9998,
    credit_h: 15599.988,
    battle_m: 2781,
    battle_h: 166860,
    exp_m: 285,
    exp_h: 17100,
    dust_m: 0.498,
    dust_h: 29.88,
    core_h: 43.326
  },
  {
    level: 78,
    credit_m: 264,
    credit_h: 15840,
    battle_m: 2840,
    battle_h: 170400,
    exp_m: 288.9999,
    exp_h: 17339.994,
    dust_m: 0.5001,
    dust_h: 30.006,
    core_h: 43.5087
  },
  {
    level: 79,
    credit_m: 265.9998,
    credit_h: 15959.988,
    battle_m: 2869,
    battle_h: 172140,
    exp_m: 292.9998,
    exp_h: 17579.988,
    dust_m: 0.5011,
    dust_h: 30.066,
    core_h: 43.5957
  },
  {
    level: 80,
    credit_m: 268.9998,
    credit_h: 16139.988,
    battle_m: 2928,
    battle_h: 175680,
    exp_m: 297,
    exp_h: 17820,
    dust_m: 0.5032,
    dust_h: 30.192,
    core_h: 43.7784
  },
  {
    level: 81,
    credit_m: 273,
    credit_h: 16380,
    battle_m: 2987,
    battle_h: 179220,
    exp_m: 300,
    exp_h: 18000,
    dust_m: 0.5053,
    dust_h: 30.318,
    core_h: 43.9611
  },
  {
    level: 82,
    credit_m: 276,
    credit_h: 16560,
    battle_m: 3047,
    battle_h: 182820,
    exp_m: 303.9999,
    exp_h: 18239.994,
    dust_m: 0.5073,
    dust_h: 30.438,
    core_h: 44.1351
  },
  {
    level: 83,
    credit_m: 279.9999,
    credit_h: 16799.994,
    battle_m: 3107,
    battle_h: 186420,
    exp_m: 307.9998,
    exp_h: 18479.988,
    dust_m: 0.5093,
    dust_h: 30.558,
    core_h: 44.3091
  },
  {
    level: 84,
    credit_m: 283.9998,
    credit_h: 17039.988,
    battle_m: 3168,
    battle_h: 190080,
    exp_m: 312,
    exp_h: 18720,
    dust_m: 0.5113,
    dust_h: 30.678,
    core_h: 44.4831
  },
  {
    level: 85,
    credit_m: 285,
    credit_h: 17100,
    battle_m: 3198,
    battle_h: 191880,
    exp_m: 315.9999,
    exp_h: 18959.994,
    dust_m: 0.5123,
    dust_h: 30.738,
    core_h: 44.5701
  },
  {
    level: 86,
    credit_m: 291,
    credit_h: 17460,
    battle_m: 3290,
    battle_h: 197400,
    exp_m: 319.9998,
    exp_h: 19199.988,
    dust_m: 0.5153,
    dust_h: 30.918,
    core_h: 44.8311
  },
  {
    level: 87,
    credit_m: 292.9998,
    credit_h: 17579.988,
    battle_m: 3321,
    battle_h: 199260,
    exp_m: 324,
    exp_h: 19440,
    dust_m: 0.5163,
    dust_h: 30.978,
    core_h: 44.9181
  },
  {
    level: 88,
    credit_m: 295.9998,
    credit_h: 17759.988,
    battle_m: 3383,
    battle_h: 202980,
    exp_m: 327,
    exp_h: 19620,
    dust_m: 0.5182,
    dust_h: 31.092,
    core_h: 45.0834
  },
  {
    level: 89,
    credit_m: 301.9998,
    credit_h: 18119.988,
    battle_m: 3477,
    battle_h: 208620,
    exp_m: 330.9999,
    exp_h: 19859.994,
    dust_m: 0.5211,
    dust_h: 31.266,
    core_h: 45.3357
  },
  {
    level: 90,
    credit_m: 306,
    credit_h: 18360,
    battle_m: 3541,
    battle_h: 212460,
    exp_m: 334.9998,
    exp_h: 20099.988,
    dust_m: 0.523,
    dust_h: 31.38,
    core_h: 45.501
  },
  {
    level: 91,
    credit_m: 309,
    credit_h: 18540,
    battle_m: 3604,
    battle_h: 216240,
    exp_m: 339,
    exp_h: 20340,
    dust_m: 0.5249,
    dust_h: 31.494,
    core_h: 45.6663
  },
  {
    level: 92,
    credit_m: 312.9999,
    credit_h: 18779.994,
    battle_m: 3668,
    battle_h: 220080,
    exp_m: 342.9999,
    exp_h: 20579.994,
    dust_m: 0.5268,
    dust_h: 31.608,
    core_h: 45.8316
  },
  {
    level: 93,
    credit_m: 316.9998,
    credit_h: 19019.988,
    battle_m: 3733,
    battle_h: 223980,
    exp_m: 346.9998,
    exp_h: 20819.988,
    dust_m: 0.5287,
    dust_h: 31.722,
    core_h: 45.9969
  },
  {
    level: 94,
    credit_m: 321.9999,
    credit_h: 19319.994,
    battle_m: 3830,
    battle_h: 229800,
    exp_m: 351,
    exp_h: 21060,
    dust_m: 0.5314,
    dust_h: 31.884,
    core_h: 46.2318
  },
  {
    level: 95,
    credit_m: 325.9998,
    credit_h: 19559.988,
    battle_m: 3896,
    battle_h: 233760,
    exp_m: 354.9999,
    exp_h: 21299.994,
    dust_m: 0.5333,
    dust_h: 31.998,
    core_h: 46.3971
  },
  {
    level: 96,
    credit_m: 330,
    credit_h: 19800,
    battle_m: 3961,
    battle_h: 237660,
    exp_m: 358.9998,
    exp_h: 21539.988,
    dust_m: 0.5351,
    dust_h: 32.106,
    core_h: 46.5537
  },
  {
    level: 97,
    credit_m: 333,
    credit_h: 19980,
    battle_m: 4028,
    battle_h: 241680,
    exp_m: 363,
    exp_h: 21780,
    dust_m: 0.5369,
    dust_h: 32.214,
    core_h: 46.7103
  },
  {
    level: 98,
    credit_m: 334.9998,
    credit_h: 20099.988,
    battle_m: 4061,
    battle_h: 243660,
    exp_m: 366.9999,
    exp_h: 22019.994,
    dust_m: 0.5378,
    dust_h: 32.268,
    core_h: 46.7886
  },
  {
    level: 99,
    credit_m: 336.9999,
    credit_h: 20219.994,
    battle_m: 4094,
    battle_h: 245640,
    exp_m: 370.9998,
    exp_h: 22259.988,
    dust_m: 0.5387,
    dust_h: 32.322,
    core_h: 46.8669
  },
  {
    level: 100,
    credit_m: 339,
    credit_h: 20340,
    battle_m: 4128,
    battle_h: 247680,
    exp_m: 375,
    exp_h: 22500,
    dust_m: 0.5396,
    dust_h: 32.376,
    core_h: 46.9452
  },
  {
    level: 101,
    credit_m: 339.6633,
    credit_h: 20379.798,
    battle_m: 4136.15,
    battle_h: 248169,
    exp_m: 378.9999,
    exp_h: 22739.994,
    dust_m: 0.5411,
    dust_h: 32.466,
    core_h: 47.0757
  },
  {
    level: 102,
    credit_m: 340.2303,
    credit_h: 20413.818,
    battle_m: 4137.827,
    battle_h: 248269.62,
    exp_m: 382.9998,
    exp_h: 22979.988,
    dust_m: 0.5426,
    dust_h: 32.556,
    core_h: 47.2062
  },
  {
    level: 103,
    credit_m: 340.7967,
    credit_h: 20447.802,
    battle_m: 4139.506,
    battle_h: 248370.36,
    exp_m: 387,
    exp_h: 23220,
    dust_m: 0.544,
    dust_h: 32.64,
    core_h: 47.328
  },
  {
    level: 104,
    credit_m: 341.3625,
    credit_h: 20481.75,
    battle_m: 4141.185,
    battle_h: 248471.1,
    exp_m: 390.9999,
    exp_h: 23459.994,
    dust_m: 0.5454,
    dust_h: 32.724,
    core_h: 47.4498
  },
  {
    level: 105,
    credit_m: 341.928,
    credit_h: 20515.68,
    battle_m: 4142.864,
    battle_h: 248571.84,
    exp_m: 394.9998,
    exp_h: 23699.988,
    dust_m: 0.5469,
    dust_h: 32.814,
    core_h: 47.5803
  },
  {
    level: 106,
    credit_m: 342.4929,
    credit_h: 20549.574,
    battle_m: 4144.543,
    battle_h: 248672.58,
    exp_m: 399,
    exp_h: 23940,
    dust_m: 0.5483,
    dust_h: 32.898,
    core_h: 47.7021
  },
  {
    level: 107,
    credit_m: 343.0569,
    credit_h: 20583.414,
    battle_m: 4146.223,
    battle_h: 248773.38,
    exp_m: 402.9999,
    exp_h: 24179.994,
    dust_m: 0.5497,
    dust_h: 32.982,
    core_h: 47.8239
  },
  {
    level: 108,
    credit_m: 343.6209,
    credit_h: 20617.254,
    battle_m: 4147.903,
    battle_h: 248874.18,
    exp_m: 406.9998,
    exp_h: 24419.988,
    dust_m: 0.5511,
    dust_h: 33.066,
    core_h: 47.9457
  },
  {
    level: 109,
    credit_m: 344.184,
    credit_h: 20651.04,
    battle_m: 4149.583,
    battle_h: 248974.98,
    exp_m: 411,
    exp_h: 24660,
    dust_m: 0.5524,
    dust_h: 33.144,
    core_h: 48.0588
  },
  {
    level: 110,
    credit_m: 344.7468,
    credit_h: 20684.808,
    battle_m: 4151.264,
    battle_h: 249075.84,
    exp_m: 414.9999,
    exp_h: 24899.994,
    dust_m: 0.5538,
    dust_h: 33.228,
    core_h: 48.1806
  },
  {
    level: 111,
    credit_m: 345.309,
    credit_h: 20718.54,
    battle_m: 4152.945,
    battle_h: 249176.7,
    exp_m: 418.9998,
    exp_h: 25139.988,
    dust_m: 0.5552,
    dust_h: 33.312,
    core_h: 48.3024
  },
  {
    level: 112,
    credit_m: 345.8706,
    credit_h: 20752.236,
    battle_m: 4154.626,
    battle_h: 249277.56,
    exp_m: 423,
    exp_h: 25380,
    dust_m: 0.5566,
    dust_h: 33.396,
    core_h: 48.4242
  },
  {
    level: 113,
    credit_m: 346.4319,
    credit_h: 20785.914,
    battle_m: 4156.308,
    battle_h: 249378.48,
    exp_m: 426.9999,
    exp_h: 25619.994,
    dust_m: 0.5579,
    dust_h: 33.474,
    core_h: 48.5373
  },
  {
    level: 114,
    credit_m: 346.9926,
    credit_h: 20819.556,
    battle_m: 4157.99,
    battle_h: 249479.4,
    exp_m: 430.9998,
    exp_h: 25859.988,
    dust_m: 0.5593,
    dust_h: 33.558,
    core_h: 48.6591
  },
  {
    level: 115,
    credit_m: 347.553,
    credit_h: 20853.18,
    battle_m: 4159.672,
    battle_h: 249580.32,
    exp_m: 435,
    exp_h: 26100,
    dust_m: 0.5606,
    dust_h: 33.636,
    core_h: 48.7722
  },
  {
    level: 116,
    credit_m: 348.1125,
    credit_h: 20886.75,
    battle_m: 4161.354,
    battle_h: 249681.24,
    exp_m: 438.9999,
    exp_h: 26339.994,
    dust_m: 0.562,
    dust_h: 33.72,
    core_h: 48.894
  },
  {
    level: 117,
    credit_m: 348.672,
    credit_h: 20920.32,
    battle_m: 4163.037,
    battle_h: 249782.22,
    exp_m: 442.9998,
    exp_h: 26579.988,
    dust_m: 0.5633,
    dust_h: 33.798,
    core_h: 49.0071
  },
  {
    level: 118,
    credit_m: 349.2306,
    credit_h: 20953.836,
    battle_m: 4164.72,
    battle_h: 249883.2,
    exp_m: 447,
    exp_h: 26820,
    dust_m: 0.5646,
    dust_h: 33.876,
    core_h: 49.1202
  },
  {
    level: 119,
    credit_m: 349.7889,
    credit_h: 20987.334,
    battle_m: 4166.403,
    battle_h: 249984.18,
    exp_m: 450.9999,
    exp_h: 27059.994,
    dust_m: 0.5659,
    dust_h: 33.954,
    core_h: 49.2333
  },
  {
    level: 120,
    credit_m: 350.3469,
    credit_h: 21020.814,
    battle_m: 4168.087,
    battle_h: 250085.22,
    exp_m: 454.9998,
    exp_h: 27299.988,
    dust_m: 0.5673,
    dust_h: 34.038,
    core_h: 49.3551
  },
  {
    level: 121,
    credit_m: 350.9043,
    credit_h: 21054.258,
    battle_m: 4169.771,
    battle_h: 250186.26,
    exp_m: 459,
    exp_h: 27540,
    dust_m: 0.5686,
    dust_h: 34.116,
    core_h: 49.4682
  },
  {
    level: 122,
    credit_m: 351.4611,
    credit_h: 21087.666,
    battle_m: 4171.455,
    battle_h: 250287.3,
    exp_m: 462.9999,
    exp_h: 27779.994,
    dust_m: 0.5699,
    dust_h: 34.194,
    core_h: 49.5813
  },
  {
    level: 123,
    credit_m: 352.0176,
    credit_h: 21121.056,
    battle_m: 4173.14,
    battle_h: 250388.4,
    exp_m: 466.9998,
    exp_h: 28019.988,
    dust_m: 0.5712,
    dust_h: 34.272,
    core_h: 49.6944
  },
  {
    level: 124,
    credit_m: 352.5735,
    credit_h: 21154.41,
    battle_m: 4174.824,
    battle_h: 250489.44,
    exp_m: 471.9999,
    exp_h: 28319.994,
    dust_m: 0.5724,
    dust_h: 34.344,
    core_h: 49.7988
  },
  {
    level: 125,
    credit_m: 353.1291,
    credit_h: 21187.746,
    battle_m: 4176.509,
    battle_h: 250590.54,
    exp_m: 475.9998,
    exp_h: 28559.988,
    dust_m: 0.5737,
    dust_h: 34.422,
    core_h: 49.9119
  },
  {
    level: 126,
    credit_m: 353.6841,
    credit_h: 21221.046,
    battle_m: 4178.194,
    battle_h: 250691.64,
    exp_m: 480,
    exp_h: 28800,
    dust_m: 0.575,
    dust_h: 34.5,
    core_h: 50.025
  },
  {
    level: 127,
    credit_m: 354.2388,
    credit_h: 21254.328,
    battle_m: 4179.88,
    battle_h: 250792.8,
    exp_m: 483.9999,
    exp_h: 29039.994,
    dust_m: 0.5763,
    dust_h: 34.578,
    core_h: 50.1381
  },
  {
    level: 128,
    credit_m: 354.7932,
    credit_h: 21287.592,
    battle_m: 4181.566,
    battle_h: 250893.96,
    exp_m: 487.9998,
    exp_h: 29279.988,
    dust_m: 0.5775,
    dust_h: 34.65,
    core_h: 50.2425
  },
  {
    level: 129,
    credit_m: 355.347,
    credit_h: 21320.82,
    battle_m: 4183.252,
    battle_h: 250995.12,
    exp_m: 492,
    exp_h: 29520,
    dust_m: 0.5788,
    dust_h: 34.728,
    core_h: 50.3556
  },
  {
    level: 130,
    credit_m: 355.9002,
    credit_h: 21354.012,
    battle_m: 4184.938,
    battle_h: 251096.28,
    exp_m: 495.9999,
    exp_h: 29759.994,
    dust_m: 0.5801,
    dust_h: 34.806,
    core_h: 50.4687
  },
  {
    level: 131,
    credit_m: 356.4531,
    credit_h: 21387.186,
    battle_m: 4186.625,
    battle_h: 251197.5,
    exp_m: 499.9998,
    exp_h: 29999.988,
    dust_m: 0.5813,
    dust_h: 34.878,
    core_h: 50.5731
  },
  {
    level: 132,
    credit_m: 357.0057,
    credit_h: 21420.342,
    battle_m: 4188.311,
    battle_h: 251298.66,
    exp_m: 504.9999,
    exp_h: 30299.994,
    dust_m: 0.5826,
    dust_h: 34.956,
    core_h: 50.6862
  },
  {
    level: 133,
    credit_m: 357.5577,
    credit_h: 21453.462,
    battle_m: 4189.999,
    battle_h: 251399.94,
    exp_m: 508.9998,
    exp_h: 30539.988,
    dust_m: 0.5838,
    dust_h: 35.028,
    core_h: 50.7906
  },
  {
    level: 134,
    credit_m: 358.1094,
    credit_h: 21486.564,
    battle_m: 4191.686,
    battle_h: 251501.16,
    exp_m: 513,
    exp_h: 30780,
    dust_m: 0.585,
    dust_h: 35.1,
    core_h: 50.895
  },
  {
    level: 135,
    credit_m: 358.6608,
    credit_h: 21519.648,
    battle_m: 4193.373,
    battle_h: 251602.38,
    exp_m: 516.9999,
    exp_h: 31019.994,
    dust_m: 0.5863,
    dust_h: 35.178,
    core_h: 51.0081
  },
  {
    level: 136,
    credit_m: 359.2116,
    credit_h: 21552.696,
    battle_m: 4195.061,
    battle_h: 251703.66,
    exp_m: 520.9998,
    exp_h: 31259.988,
    dust_m: 0.5875,
    dust_h: 35.25,
    core_h: 51.1125
  },
  {
    level: 137,
    credit_m: 359.7621,
    credit_h: 21585.726,
    battle_m: 4196.749,
    battle_h: 251804.94,
    exp_m: 525,
    exp_h: 31500,
    dust_m: 0.5887,
    dust_h: 35.322,
    core_h: 51.2169
  },
  {
    level: 138,
    credit_m: 360.3123,
    credit_h: 21618.738,
    battle_m: 4198.438,
    battle_h: 251906.28,
    exp_m: 528.9999,
    exp_h: 31739.994,
    dust_m: 0.5899,
    dust_h: 35.394,
    core_h: 51.3213
  },
  {
    level: 139,
    credit_m: 360.8619,
    credit_h: 21651.714,
    battle_m: 4200.126,
    battle_h: 252007.56,
    exp_m: 534,
    exp_h: 32040,
    dust_m: 0.5911,
    dust_h: 35.466,
    core_h: 51.4257
  },
  {
    level: 140,
    credit_m: 361.4112,
    credit_h: 21684.672,
    battle_m: 4201.815,
    battle_h: 252108.9,
    exp_m: 537.9999,
    exp_h: 32279.994,
    dust_m: 0.5923,
    dust_h: 35.538,
    core_h: 51.5301
  },
  {
    level: 141,
    credit_m: 361.9599,
    credit_h: 21717.594,
    battle_m: 4203.504,
    battle_h: 252210.24,
    exp_m: 541.9998,
    exp_h: 32519.988,
    dust_m: 0.5935,
    dust_h: 35.61,
    core_h: 51.6345
  },
  {
    level: 142,
    credit_m: 362.5086,
    credit_h: 21750.516,
    battle_m: 4205.193,
    battle_h: 252311.58,
    exp_m: 546,
    exp_h: 32760,
    dust_m: 0.5947,
    dust_h: 35.682,
    core_h: 51.7389
  },
  {
    level: 143,
    credit_m: 363.0567,
    credit_h: 21783.402,
    battle_m: 4206.883,
    battle_h: 252412.98,
    exp_m: 549.9999,
    exp_h: 32999.994,
    dust_m: 0.5959,
    dust_h: 35.754,
    core_h: 51.8433
  },
  {
    level: 144,
    credit_m: 363.6042,
    credit_h: 21816.252,
    battle_m: 4208.573,
    battle_h: 252514.38,
    exp_m: 555,
    exp_h: 33300,
    dust_m: 0.5971,
    dust_h: 35.826,
    core_h: 51.9477
  },
  {
    level: 145,
    credit_m: 364.1517,
    credit_h: 21849.102,
    battle_m: 4210.263,
    battle_h: 252615.78,
    exp_m: 558.9999,
    exp_h: 33539.994,
    dust_m: 0.5983,
    dust_h: 35.898,
    core_h: 52.0521
  },
  {
    level: 146,
    credit_m: 364.6986,
    credit_h: 21881.916,
    battle_m: 4211.953,
    battle_h: 252717.18,
    exp_m: 562.9998,
    exp_h: 33779.988,
    dust_m: 0.5994,
    dust_h: 35.964,
    core_h: 52.1478
  },
  {
    level: 147,
    credit_m: 365.2452,
    credit_h: 21914.712,
    battle_m: 4213.643,
    battle_h: 252818.58,
    exp_m: 567,
    exp_h: 34020,
    dust_m: 0.6006,
    dust_h: 36.036,
    core_h: 52.2522
  },
  {
    level: 148,
    credit_m: 365.7915,
    credit_h: 21947.49,
    battle_m: 4215.334,
    battle_h: 252920.04,
    exp_m: 570.9999,
    exp_h: 34259.994,
    dust_m: 0.6018,
    dust_h: 36.108,
    core_h: 52.3566
  },
  {
    level: 149,
    credit_m: 366.3372,
    credit_h: 21980.232,
    battle_m: 4217.025,
    battle_h: 253021.5,
    exp_m: 576,
    exp_h: 34560,
    dust_m: 0.6029,
    dust_h: 36.174,
    core_h: 52.4523
  },
  {
    level: 150,
    credit_m: 366.8826,
    credit_h: 22012.956,
    battle_m: 4218.716,
    battle_h: 253122.96,
    exp_m: 579.9999,
    exp_h: 34799.994,
    dust_m: 0.6041,
    dust_h: 36.246,
    core_h: 52.5567
  },
  {
    level: 151,
    credit_m: 367.428,
    credit_h: 22045.68,
    battle_m: 4220.408,
    battle_h: 253224.48,
    exp_m: 583.9998,
    exp_h: 35039.988,
    dust_m: 0.6052,
    dust_h: 36.312,
    core_h: 52.6524
  },
  {
    level: 152,
    credit_m: 367.9725,
    credit_h: 22078.35,
    battle_m: 4222.099,
    battle_h: 253325.94,
    exp_m: 588,
    exp_h: 35280,
    dust_m: 0.6064,
    dust_h: 36.384,
    core_h: 52.7568
  },
  {
    level: 153,
    credit_m: 368.517,
    credit_h: 22111.02,
    battle_m: 4223.791,
    battle_h: 253427.46,
    exp_m: 591.9999,
    exp_h: 35519.994,
    dust_m: 0.6075,
    dust_h: 36.45,
    core_h: 52.8525
  },
  {
    level: 154,
    credit_m: 369.0612,
    credit_h: 22143.672,
    battle_m: 4225.483,
    battle_h: 253528.98,
    exp_m: 597,
    exp_h: 35820,
    dust_m: 0.6087,
    dust_h: 36.522,
    core_h: 52.9569
  },
  {
    level: 155,
    credit_m: 369.6048,
    credit_h: 22176.288,
    battle_m: 4227.175,
    battle_h: 253630.5,
    exp_m: 600.9999,
    exp_h: 36059.994,
    dust_m: 0.6098,
    dust_h: 36.588,
    core_h: 53.0526
  },
  {
    level: 156,
    credit_m: 370.1481,
    credit_h: 22208.886,
    battle_m: 4228.868,
    battle_h: 253732.08,
    exp_m: 604.9998,
    exp_h: 36299.988,
    dust_m: 0.6109,
    dust_h: 36.654,
    core_h: 53.1483
  },
  {
    level: 157,
    credit_m: 370.6911,
    credit_h: 22241.466,
    battle_m: 4230.56,
    battle_h: 253833.6,
    exp_m: 609,
    exp_h: 36540,
    dust_m: 0.6121,
    dust_h: 36.726,
    core_h: 53.2527
  },
  {
    level: 158,
    credit_m: 371.2338,
    credit_h: 22274.028,
    battle_m: 4232.254,
    battle_h: 253935.24,
    exp_m: 613.9998,
    exp_h: 36839.988,
    dust_m: 0.6132,
    dust_h: 36.792,
    core_h: 53.3484
  },
  {
    level: 159,
    credit_m: 371.7759,
    credit_h: 22306.554,
    battle_m: 4233.947,
    battle_h: 254036.82,
    exp_m: 618,
    exp_h: 37080,
    dust_m: 0.6143,
    dust_h: 36.858,
    core_h: 53.4441
  },
  {
    level: 160,
    credit_m: 372.318,
    credit_h: 22339.08,
    battle_m: 4235.64,
    battle_h: 254138.4,
    exp_m: 621.9999,
    exp_h: 37319.994,
    dust_m: 0.6154,
    dust_h: 36.924,
    core_h: 53.5398
  },
  {
    level: 161,
    credit_m: 372.8595,
    credit_h: 22371.57,
    battle_m: 4237.334,
    battle_h: 254240.04,
    exp_m: 625.9998,
    exp_h: 37559.988,
    dust_m: 0.6165,
    dust_h: 36.99,
    core_h: 53.6355
  },
  {
    level: 162,
    credit_m: 373.401,
    credit_h: 22404.06,
    battle_m: 4239.027,
    battle_h: 254341.62,
    exp_m: 630.9999,
    exp_h: 37859.994,
    dust_m: 0.6176,
    dust_h: 37.056,
    core_h: 53.7312
  },
  {
    level: 163,
    credit_m: 373.9419,
    credit_h: 22436.514,
    battle_m: 4240.721,
    battle_h: 254443.26,
    exp_m: 634.9998,
    exp_h: 38099.988,
    dust_m: 0.6187,
    dust_h: 37.122,
    core_h: 53.8269
  },
  {
    level: 164,
    credit_m: 374.4825,
    credit_h: 22468.95,
    battle_m: 4242.416,
    battle_h: 254544.96,
    exp_m: 639,
    exp_h: 38340,
    dust_m: 0.6198,
    dust_h: 37.188,
    core_h: 53.9226
  },
  {
    level: 165,
    credit_m: 375.0228,
    credit_h: 22501.368,
    battle_m: 4244.11,
    battle_h: 254646.6,
    exp_m: 642.9999,
    exp_h: 38579.994,
    dust_m: 0.6209,
    dust_h: 37.254,
    core_h: 54.0183
  },
  {
    level: 166,
    credit_m: 375.5628,
    credit_h: 22533.768,
    battle_m: 4245.805,
    battle_h: 254748.3,
    exp_m: 648,
    exp_h: 38880,
    dust_m: 0.622,
    dust_h: 37.32,
    core_h: 54.114
  },
  {
    level: 167,
    credit_m: 376.1022,
    credit_h: 22566.132,
    battle_m: 4247.499,
    battle_h: 254849.94,
    exp_m: 651.9999,
    exp_h: 39119.994,
    dust_m: 0.6231,
    dust_h: 37.386,
    core_h: 54.2097
  },
  {
    level: 168,
    credit_m: 376.6416,
    credit_h: 22598.496,
    battle_m: 4249.194,
    battle_h: 254951.64,
    exp_m: 655.9998,
    exp_h: 39359.988,
    dust_m: 0.6242,
    dust_h: 37.452,
    core_h: 54.3054
  },
  {
    level: 169,
    credit_m: 377.1807,
    credit_h: 22630.842,
    battle_m: 4250.89,
    battle_h: 255053.4,
    exp_m: 660.9999,
    exp_h: 39659.994,
    dust_m: 0.6253,
    dust_h: 37.518,
    core_h: 54.4011
  },
  {
    level: 170,
    credit_m: 377.7192,
    credit_h: 22663.152,
    battle_m: 4252.585,
    battle_h: 255155.1,
    exp_m: 664.9998,
    exp_h: 39899.988,
    dust_m: 0.6263,
    dust_h: 37.578,
    core_h: 54.4881
  },
  {
    level: 171,
    credit_m: 378.2577,
    credit_h: 22695.462,
    battle_m: 4254.281,
    battle_h: 255256.86,
    exp_m: 669,
    exp_h: 40140,
    dust_m: 0.6274,
    dust_h: 37.644,
    core_h: 54.5838
  },
  {
    level: 172,
    credit_m: 378.7956,
    credit_h: 22727.736,
    battle_m: 4255.976,
    battle_h: 255358.56,
    exp_m: 673.9998,
    exp_h: 40439.988,
    dust_m: 0.6285,
    dust_h: 37.71,
    core_h: 54.6795
  },
  {
    level: 173,
    credit_m: 379.3335,
    credit_h: 22760.01,
    battle_m: 4257.672,
    battle_h: 255460.32,
    exp_m: 678,
    exp_h: 40680,
    dust_m: 0.6295,
    dust_h: 37.77,
    core_h: 54.7665
  },
  {
    level: 174,
    credit_m: 379.8708,
    credit_h: 22792.248,
    battle_m: 4259.369,
    battle_h: 255562.14,
    exp_m: 681.9999,
    exp_h: 40919.994,
    dust_m: 0.6306,
    dust_h: 37.836,
    core_h: 54.8622
  },
  {
    level: 175,
    credit_m: 380.4081,
    credit_h: 22824.486,
    battle_m: 4261.065,
    battle_h: 255663.9,
    exp_m: 685.9998,
    exp_h: 41159.988,
    dust_m: 0.6317,
    dust_h: 37.902,
    core_h: 54.9579
  },
  {
    level: 176,
    credit_m: 380.9448,
    credit_h: 22856.688,
    battle_m: 4262.762,
    battle_h: 255765.72,
    exp_m: 690.9999,
    exp_h: 41459.994,
    dust_m: 0.6327,
    dust_h: 37.962,
    core_h: 55.0449
  },
  {
    level: 177,
    credit_m: 381.4812,
    credit_h: 22888.872,
    battle_m: 4264.458,
    battle_h: 255867.48,
    exp_m: 694.9998,
    exp_h: 41699.988,
    dust_m: 0.6338,
    dust_h: 38.028,
    core_h: 55.1406
  },
  {
    level: 178,
    credit_m: 382.0176,
    credit_h: 22921.056,
    battle_m: 4266.155,
    battle_h: 255969.3,
    exp_m: 699,
    exp_h: 41940,
    dust_m: 0.6348,
    dust_h: 38.088,
    core_h: 55.2276
  },
  {
    level: 179,
    credit_m: 382.5534,
    credit_h: 22953.204,
    battle_m: 4267.853,
    battle_h: 256071.18,
    exp_m: 703.9998,
    exp_h: 42239.988,
    dust_m: 0.6359,
    dust_h: 38.154,
    core_h: 55.3233
  },
  {
    level: 180,
    credit_m: 383.0889,
    credit_h: 22985.334,
    battle_m: 4269.55,
    battle_h: 256173,
    exp_m: 708,
    exp_h: 42480,
    dust_m: 0.6369,
    dust_h: 38.214,
    core_h: 55.4103
  },
  {
    level: 181,
    credit_m: 383.6241,
    credit_h: 23017.446,
    battle_m: 4271.247,
    battle_h: 256274.82,
    exp_m: 711.9999,
    exp_h: 42719.994,
    dust_m: 0.6379,
    dust_h: 38.274,
    core_h: 55.4973
  },
  {
    level: 182,
    credit_m: 384.1593,
    credit_h: 23049.558,
    battle_m: 4272.945,
    battle_h: 256376.7,
    exp_m: 717,
    exp_h: 43020,
    dust_m: 0.639,
    dust_h: 38.34,
    core_h: 55.593
  },
  {
    level: 183,
    credit_m: 384.6939,
    credit_h: 23081.634,
    battle_m: 4274.643,
    battle_h: 256478.58,
    exp_m: 720.9999,
    exp_h: 43259.994,
    dust_m: 0.64,
    dust_h: 38.4,
    core_h: 55.68
  },
  {
    level: 184,
    credit_m: 385.2285,
    credit_h: 23113.71,
    battle_m: 4276.341,
    battle_h: 256580.46,
    exp_m: 724.9998,
    exp_h: 43499.988,
    dust_m: 0.641,
    dust_h: 38.46,
    core_h: 55.767
  },
  {
    level: 185,
    credit_m: 385.7625,
    credit_h: 23145.75,
    battle_m: 4278.039,
    battle_h: 256682.34,
    exp_m: 729.9999,
    exp_h: 43799.994,
    dust_m: 0.6421,
    dust_h: 38.526,
    core_h: 55.8627
  },
  {
    level: 186,
    credit_m: 386.2962,
    credit_h: 23177.772,
    battle_m: 4279.738,
    battle_h: 256784.28,
    exp_m: 733.9998,
    exp_h: 44039.988,
    dust_m: 0.6431,
    dust_h: 38.586,
    core_h: 55.9497
  },
  {
    level: 187,
    credit_m: 386.8299,
    credit_h: 23209.794,
    battle_m: 4281.437,
    battle_h: 256886.22,
    exp_m: 738.9999,
    exp_h: 44339.994,
    dust_m: 0.6441,
    dust_h: 38.646,
    core_h: 56.0367
  },
  {
    level: 188,
    credit_m: 387.363,
    credit_h: 23241.78,
    battle_m: 4283.135,
    battle_h: 256988.1,
    exp_m: 742.9998,
    exp_h: 44579.988,
    dust_m: 0.6451,
    dust_h: 38.706,
    core_h: 56.1237
  },
  {
    level: 189,
    credit_m: 387.8961,
    credit_h: 23273.766,
    battle_m: 4284.834,
    battle_h: 257090.04,
    exp_m: 747,
    exp_h: 44820,
    dust_m: 0.6461,
    dust_h: 38.766,
    core_h: 56.2107
  },
  {
    level: 190,
    credit_m: 388.4289,
    credit_h: 23305.734,
    battle_m: 4286.534,
    battle_h: 257192.04,
    exp_m: 751.9998,
    exp_h: 45119.988,
    dust_m: 0.6471,
    dust_h: 38.826,
    core_h: 56.2977
  },
  {
    level: 191,
    credit_m: 388.9611,
    credit_h: 23337.666,
    battle_m: 4288.233,
    battle_h: 257293.98,
    exp_m: 756,
    exp_h: 45360,
    dust_m: 0.6481,
    dust_h: 38.886,
    core_h: 56.3847
  },
  {
    level: 192,
    credit_m: 389.4933,
    credit_h: 23369.598,
    battle_m: 4289.933,
    battle_h: 257395.98,
    exp_m: 759.9999,
    exp_h: 45599.994,
    dust_m: 0.6492,
    dust_h: 38.952,
    core_h: 56.4804
  },
  {
    level: 193,
    credit_m: 390.0252,
    credit_h: 23401.512,
    battle_m: 4291.632,
    battle_h: 257497.92,
    exp_m: 765,
    exp_h: 45900,
    dust_m: 0.6502,
    dust_h: 39.012,
    core_h: 56.5674
  },
  {
    level: 194,
    credit_m: 390.5568,
    credit_h: 23433.408,
    battle_m: 4293.332,
    battle_h: 257599.92,
    exp_m: 768.9999,
    exp_h: 46139.994,
    dust_m: 0.6511,
    dust_h: 39.066,
    core_h: 56.6457
  },
  {
    level: 195,
    credit_m: 391.0881,
    credit_h: 23465.286,
    battle_m: 4295.032,
    battle_h: 257701.92,
    exp_m: 774,
    exp_h: 46440,
    dust_m: 0.6521,
    dust_h: 39.126,
    core_h: 56.7327
  },
  {
    level: 196,
    credit_m: 391.6191,
    credit_h: 23497.146,
    battle_m: 4296.732,
    battle_h: 257803.92,
    exp_m: 777.9999,
    exp_h: 46679.994,
    dust_m: 0.6531,
    dust_h: 39.186,
    core_h: 56.8197
  },
  {
    level: 197,
    credit_m: 392.1501,
    credit_h: 23529.006,
    battle_m: 4298.433,
    battle_h: 257905.98,
    exp_m: 781.9998,
    exp_h: 46919.988,
    dust_m: 0.6541,
    dust_h: 39.246,
    core_h: 56.9067
  },
  {
    level: 198,
    credit_m: 392.6805,
    credit_h: 23560.83,
    battle_m: 4300.133,
    battle_h: 258007.98,
    exp_m: 786.9999,
    exp_h: 47219.994,
    dust_m: 0.6551,
    dust_h: 39.306,
    core_h: 56.9937
  },
  {
    level: 199,
    credit_m: 393.2106,
    credit_h: 23592.636,
    battle_m: 4301.834,
    battle_h: 258110.04,
    exp_m: 790.9998,
    exp_h: 47459.988,
    dust_m: 0.6561,
    dust_h: 39.366,
    core_h: 57.0807
  },
  {
    level: 200,
    credit_m: 393.7407,
    credit_h: 23624.442,
    battle_m: 4303.535,
    battle_h: 258212.1,
    exp_m: 795.9999,
    exp_h: 47759.994,
    dust_m: 0.6571,
    dust_h: 39.426,
    core_h: 57.1677
  },
  {
    level: 201,
    credit_m: 394.2705,
    credit_h: 23656.23,
    battle_m: 4305.236,
    battle_h: 258314.16,
    exp_m: 799.9998,
    exp_h: 47999.988,
    dust_m: 0.6581,
    dust_h: 39.486,
    core_h: 57.2547
  },
  {
    level: 202,
    credit_m: 394.7997,
    credit_h: 23687.982,
    battle_m: 4306.938,
    battle_h: 258416.28,
    exp_m: 804,
    exp_h: 48240,
    dust_m: 0.659,
    dust_h: 39.54,
    core_h: 57.333
  },
  {
    level: 203,
    credit_m: 395.3289,
    credit_h: 23719.734,
    battle_m: 4308.639,
    battle_h: 258518.34,
    exp_m: 808.9998,
    exp_h: 48539.988,
    dust_m: 0.66,
    dust_h: 39.6,
    core_h: 57.42
  },
  {
    level: 204,
    credit_m: 395.8578,
    credit_h: 23751.468,
    battle_m: 4310.341,
    battle_h: 258620.46,
    exp_m: 813,
    exp_h: 48780,
    dust_m: 0.661,
    dust_h: 39.66,
    core_h: 57.507
  },
  {
    level: 205,
    credit_m: 396.3864,
    credit_h: 23783.184,
    battle_m: 4312.043,
    battle_h: 258722.58,
    exp_m: 817.9998,
    exp_h: 49079.988,
    dust_m: 0.6619,
    dust_h: 39.714,
    core_h: 57.5853
  },
  {
    level: 206,
    credit_m: 396.915,
    credit_h: 23814.9,
    battle_m: 4313.744,
    battle_h: 258824.64,
    exp_m: 822,
    exp_h: 49320,
    dust_m: 0.6629,
    dust_h: 39.774,
    core_h: 57.6723
  },
  {
    level: 207,
    credit_m: 397.443,
    credit_h: 23846.58,
    battle_m: 4315.447,
    battle_h: 258926.82,
    exp_m: 825.9999,
    exp_h: 49559.994,
    dust_m: 0.6639,
    dust_h: 39.834,
    core_h: 57.7593
  },
  {
    level: 208,
    credit_m: 397.971,
    credit_h: 23878.26,
    battle_m: 4317.149,
    battle_h: 259028.94,
    exp_m: 831,
    exp_h: 49860,
    dust_m: 0.6648,
    dust_h: 39.888,
    core_h: 57.8376
  },
  {
    level: 209,
    credit_m: 398.4987,
    credit_h: 23909.922,
    battle_m: 4318.851,
    battle_h: 259131.06,
    exp_m: 834.9999,
    exp_h: 50099.994,
    dust_m: 0.6658,
    dust_h: 39.948,
    core_h: 57.9246
  },
  {
    level: 210,
    credit_m: 399.0261,
    credit_h: 23941.566,
    battle_m: 4320.554,
    battle_h: 259233.24,
    exp_m: 840,
    exp_h: 50400,
    dust_m: 0.6667,
    dust_h: 40.002,
    core_h: 58.0029
  },
  {
    level: 211,
    credit_m: 399.5532,
    credit_h: 23973.192,
    battle_m: 4322.257,
    battle_h: 259335.42,
    exp_m: 843.9999,
    exp_h: 50639.994,
    dust_m: 0.6677,
    dust_h: 40.062,
    core_h: 58.0899
  },
  {
    level: 212,
    credit_m: 400.08,
    credit_h: 24004.8,
    battle_m: 4323.96,
    battle_h: 259437.6,
    exp_m: 849,
    exp_h: 50940,
    dust_m: 0.6686,
    dust_h: 40.116,
    core_h: 58.1682
  },
  {
    level: 213,
    credit_m: 400.6065,
    credit_h: 24036.39,
    battle_m: 4325.663,
    battle_h: 259539.78,
    exp_m: 852.9999,
    exp_h: 51179.994,
    dust_m: 0.6696,
    dust_h: 40.176,
    core_h: 58.2552
  },
  {
    level: 214,
    credit_m: 401.133,
    credit_h: 24067.98,
    battle_m: 4327.366,
    battle_h: 259641.96,
    exp_m: 856.9998,
    exp_h: 51419.988,
    dust_m: 0.6705,
    dust_h: 40.23,
    core_h: 58.3335
  },
  {
    level: 215,
    credit_m: 401.6592,
    credit_h: 24099.552,
    battle_m: 4329.069,
    battle_h: 259744.14,
    exp_m: 861.9999,
    exp_h: 51719.994,
    dust_m: 0.6715,
    dust_h: 40.29,
    core_h: 58.4205
  },
  {
    level: 216,
    credit_m: 402.1851,
    credit_h: 24131.106,
    battle_m: 4330.773,
    battle_h: 259846.38,
    exp_m: 865.9998,
    exp_h: 51959.988,
    dust_m: 0.6724,
    dust_h: 40.344,
    core_h: 58.4988
  },
  {
    level: 217,
    credit_m: 402.7107,
    credit_h: 24162.642,
    battle_m: 4332.477,
    battle_h: 259948.62,
    exp_m: 870.9999,
    exp_h: 52259.994,
    dust_m: 0.6733,
    dust_h: 40.398,
    core_h: 58.5771
  },
  {
    level: 218,
    credit_m: 403.236,
    credit_h: 24194.16,
    battle_m: 4334.18,
    battle_h: 260050.8,
    exp_m: 874.9998,
    exp_h: 52499.988,
    dust_m: 0.6743,
    dust_h: 40.458,
    core_h: 58.6641
  },
  {
    level: 219,
    credit_m: 403.7613,
    credit_h: 24225.678,
    battle_m: 4335.885,
    battle_h: 260153.1,
    exp_m: 879.9999,
    exp_h: 52799.994,
    dust_m: 0.6752,
    dust_h: 40.512,
    core_h: 58.7424
  },
  {
    level: 220,
    credit_m: 404.2863,
    credit_h: 24257.178,
    battle_m: 4337.589,
    battle_h: 260255.34,
    exp_m: 883.9998,
    exp_h: 53039.988,
    dust_m: 0.6761,
    dust_h: 40.566,
    core_h: 58.8207
  },
  {
    level: 221,
    credit_m: 404.811,
    credit_h: 24288.66,
    battle_m: 4339.293,
    battle_h: 260357.58,
    exp_m: 888.9999,
    exp_h: 53339.994,
    dust_m: 0.6771,
    dust_h: 40.626,
    core_h: 58.9077
  },
  {
    level: 222,
    credit_m: 405.3354,
    credit_h: 24320.124,
    battle_m: 4340.998,
    battle_h: 260459.88,
    exp_m: 892.9998,
    exp_h: 53579.988,
    dust_m: 0.678,
    dust_h: 40.68,
    core_h: 58.986
  },
  {
    level: 223,
    credit_m: 405.8595,
    credit_h: 24351.57,
    battle_m: 4342.702,
    battle_h: 260562.12,
    exp_m: 897.9999,
    exp_h: 53879.994,
    dust_m: 0.6789,
    dust_h: 40.734,
    core_h: 59.0643
  },
  {
    level: 224,
    credit_m: 406.3836,
    credit_h: 24383.016,
    battle_m: 4344.407,
    battle_h: 260664.42,
    exp_m: 901.9998,
    exp_h: 54119.988,
    dust_m: 0.6798,
    dust_h: 40.788,
    core_h: 59.1426
  },
  {
    level: 225,
    credit_m: 406.9074,
    credit_h: 24414.444,
    battle_m: 4346.112,
    battle_h: 260766.72,
    exp_m: 906.9999,
    exp_h: 54419.994,
    dust_m: 0.6807,
    dust_h: 40.842,
    core_h: 59.2209
  },
  {
    level: 226,
    credit_m: 407.4309,
    credit_h: 24445.854,
    battle_m: 4347.817,
    battle_h: 260869.02,
    exp_m: 910.9998,
    exp_h: 54659.988,
    dust_m: 0.6817,
    dust_h: 40.902,
    core_h: 59.3079
  },
  {
    level: 227,
    credit_m: 407.9541,
    credit_h: 24477.246,
    battle_m: 4349.522,
    battle_h: 260971.32,
    exp_m: 915.9999,
    exp_h: 54959.994,
    dust_m: 0.6826,
    dust_h: 40.956,
    core_h: 59.3862
  },
  {
    level: 228,
    credit_m: 408.477,
    credit_h: 24508.62,
    battle_m: 4351.228,
    battle_h: 261073.68,
    exp_m: 919.9998,
    exp_h: 55199.988,
    dust_m: 0.6835,
    dust_h: 41.01,
    core_h: 59.4645
  },
  {
    level: 229,
    credit_m: 408.9999,
    credit_h: 24539.994,
    battle_m: 4352.933,
    battle_h: 261175.98,
    exp_m: 924.9999,
    exp_h: 55499.994,
    dust_m: 0.6844,
    dust_h: 41.064,
    core_h: 59.5428
  },
  {
    level: 230,
    credit_m: 409.5225,
    credit_h: 24571.35,
    battle_m: 4354.639,
    battle_h: 261278.34,
    exp_m: 928.9998,
    exp_h: 55739.988,
    dust_m: 0.6853,
    dust_h: 41.118,
    core_h: 59.6211
  },
  {
    level: 231,
    credit_m: 410.0448,
    credit_h: 24602.688,
    battle_m: 4356.345,
    battle_h: 261380.7,
    exp_m: 933.9999,
    exp_h: 56039.994,
    dust_m: 0.6862,
    dust_h: 41.172,
    core_h: 59.6994
  },
  {
    level: 232,
    credit_m: 410.5671,
    credit_h: 24634.026,
    battle_m: 4358.051,
    battle_h: 261483.06,
    exp_m: 937.9998,
    exp_h: 56279.988,
    dust_m: 0.6871,
    dust_h: 41.226,
    core_h: 59.7777
  },
  {
    level: 233,
    credit_m: 411.0891,
    credit_h: 24665.346,
    battle_m: 4359.757,
    battle_h: 261585.42,
    exp_m: 942.9999,
    exp_h: 56579.994,
    dust_m: 0.688,
    dust_h: 41.28,
    core_h: 59.856
  },
  {
    level: 234,
    credit_m: 411.6105,
    credit_h: 24696.63,
    battle_m: 4361.464,
    battle_h: 261687.84,
    exp_m: 946.9998,
    exp_h: 56819.988,
    dust_m: 0.6889,
    dust_h: 41.334,
    core_h: 59.9343
  },
  {
    level: 235,
    credit_m: 412.1322,
    credit_h: 24727.932,
    battle_m: 4363.17,
    battle_h: 261790.2,
    exp_m: 951.9999,
    exp_h: 57119.994,
    dust_m: 0.6898,
    dust_h: 41.388,
    core_h: 60.0126
  },
  {
    level: 236,
    credit_m: 412.6533,
    credit_h: 24759.198,
    battle_m: 4364.876,
    battle_h: 261892.56,
    exp_m: 955.9998,
    exp_h: 57359.988,
    dust_m: 0.6907,
    dust_h: 41.442,
    core_h: 60.0909
  },
  {
    level: 237,
    credit_m: 413.1744,
    credit_h: 24790.464,
    battle_m: 4366.583,
    battle_h: 261994.98,
    exp_m: 960.9999,
    exp_h: 57659.994,
    dust_m: 0.6916,
    dust_h: 41.496,
    core_h: 60.1692
  },
  {
    level: 238,
    credit_m: 413.6952,
    credit_h: 24821.712,
    battle_m: 4368.29,
    battle_h: 262097.4,
    exp_m: 964.9998,
    exp_h: 57899.988,
    dust_m: 0.6925,
    dust_h: 41.55,
    core_h: 60.2475
  },
  {
    level: 239,
    credit_m: 414.2157,
    credit_h: 24852.942,
    battle_m: 4369.997,
    battle_h: 262199.82,
    exp_m: 969.9999,
    exp_h: 58199.994,
    dust_m: 0.6933,
    dust_h: 41.598,
    core_h: 60.3171
  },
  {
    level: 240,
    credit_m: 414.7362,
    credit_h: 24884.172,
    battle_m: 4371.704,
    battle_h: 262302.24,
    exp_m: 973.9998,
    exp_h: 58439.988,
    dust_m: 0.6942,
    dust_h: 41.652,
    core_h: 60.3954
  },
  {
    level: 241,
    credit_m: 415.2564,
    credit_h: 24915.384,
    battle_m: 4373.412,
    battle_h: 262404.72,
    exp_m: 978.9999,
    exp_h: 58739.994,
    dust_m: 0.6951,
    dust_h: 41.706,
    core_h: 60.4737
  },
  {
    level: 242,
    credit_m: 415.7763,
    credit_h: 24946.578,
    battle_m: 4375.119,
    battle_h: 262507.14,
    exp_m: 982.9998,
    exp_h: 58979.988,
    dust_m: 0.696,
    dust_h: 41.76,
    core_h: 60.552
  },
  {
    level: 243,
    credit_m: 416.2959,
    credit_h: 24977.754,
    battle_m: 4376.827,
    battle_h: 262609.62,
    exp_m: 987.9999,
    exp_h: 59279.994,
    dust_m: 0.6969,
    dust_h: 41.814,
    core_h: 60.6303
  },
  {
    level: 244,
    credit_m: 416.8155,
    credit_h: 25008.93,
    battle_m: 4378.535,
    battle_h: 262712.1,
    exp_m: 991.9998,
    exp_h: 59519.988,
    dust_m: 0.6977,
    dust_h: 41.862,
    core_h: 60.6999
  },
  {
    level: 245,
    credit_m: 417.3348,
    credit_h: 25040.088,
    battle_m: 4380.242,
    battle_h: 262814.52,
    exp_m: 996.9999,
    exp_h: 59819.994,
    dust_m: 0.6986,
    dust_h: 41.916,
    core_h: 60.7782
  },
  {
    level: 246,
    credit_m: 417.8538,
    credit_h: 25071.228,
    battle_m: 4381.95,
    battle_h: 262917,
    exp_m: 1001,
    exp_h: 60060,
    dust_m: 0.6995,
    dust_h: 41.97,
    core_h: 60.8565
  },
  {
    level: 247,
    credit_m: 418.3728,
    credit_h: 25102.368,
    battle_m: 4383.659,
    battle_h: 263019.54,
    exp_m: 1006,
    exp_h: 60360,
    dust_m: 0.7003,
    dust_h: 42.018,
    core_h: 60.9261
  },
  {
    level: 248,
    credit_m: 418.8915,
    credit_h: 25133.49,
    battle_m: 4385.367,
    battle_h: 263122.02,
    exp_m: 1010,
    exp_h: 60600,
    dust_m: 0.7012,
    dust_h: 42.072,
    core_h: 61.0044
  },
  {
    level: 249,
    credit_m: 419.4099,
    credit_h: 25164.594,
    battle_m: 4387.075,
    battle_h: 263224.5,
    exp_m: 1015,
    exp_h: 60900,
    dust_m: 0.7021,
    dust_h: 42.126,
    core_h: 61.0827
  },
  {
    level: 250,
    credit_m: 419.928,
    credit_h: 25195.68,
    battle_m: 4388.784,
    battle_h: 263327.04,
    exp_m: 1020,
    exp_h: 61200,
    dust_m: 0.7029,
    dust_h: 42.174,
    core_h: 61.1523
  },
  {
    level: 251,
    credit_m: 420.4461,
    credit_h: 25226.766,
    battle_m: 4390.493,
    battle_h: 263429.58,
    exp_m: 1024,
    exp_h: 61440,
    dust_m: 0.7038,
    dust_h: 42.228,
    core_h: 61.2306
  },
  {
    level: 252,
    credit_m: 420.9639,
    credit_h: 25257.834,
    battle_m: 4392.201,
    battle_h: 263532.06,
    exp_m: 1029,
    exp_h: 61740,
    dust_m: 0.7047,
    dust_h: 42.282,
    core_h: 61.3089
  },
  {
    level: 253,
    credit_m: 421.4817,
    credit_h: 25288.902,
    battle_m: 4393.91,
    battle_h: 263634.6,
    exp_m: 1033,
    exp_h: 61980,
    dust_m: 0.7055,
    dust_h: 42.33,
    core_h: 61.3785
  },
  {
    level: 254,
    credit_m: 421.9989,
    credit_h: 25319.934,
    battle_m: 4395.62,
    battle_h: 263737.2,
    exp_m: 1038,
    exp_h: 62280,
    dust_m: 0.7064,
    dust_h: 42.384,
    core_h: 61.4568
  },
  {
    level: 255,
    credit_m: 422.5161,
    credit_h: 25350.966,
    battle_m: 4397.329,
    battle_h: 263839.74,
    exp_m: 1042,
    exp_h: 62520,
    dust_m: 0.7072,
    dust_h: 42.432,
    core_h: 61.5264
  },
  {
    level: 256,
    credit_m: 423.0333,
    credit_h: 25381.998,
    battle_m: 4399.038,
    battle_h: 263942.28,
    exp_m: 1047,
    exp_h: 62820,
    dust_m: 0.7081,
    dust_h: 42.486,
    core_h: 61.6047
  },
  {
    level: 257,
    credit_m: 423.5502,
    credit_h: 25413.012,
    battle_m: 4400.748,
    battle_h: 264044.88,
    exp_m: 1052,
    exp_h: 63120,
    dust_m: 0.7089,
    dust_h: 42.534,
    core_h: 61.6743
  },
  {
    level: 258,
    credit_m: 424.0668,
    credit_h: 25444.008,
    battle_m: 4402.457,
    battle_h: 264147.42,
    exp_m: 1056,
    exp_h: 63360,
    dust_m: 0.7098,
    dust_h: 42.588,
    core_h: 61.7526
  },
  {
    level: 259,
    credit_m: 424.5831,
    credit_h: 25474.986,
    battle_m: 4404.167,
    battle_h: 264250.02,
    exp_m: 1061,
    exp_h: 63660,
    dust_m: 0.7106,
    dust_h: 42.636,
    core_h: 61.8222
  },
  {
    level: 260,
    credit_m: 425.0994,
    credit_h: 25505.964,
    battle_m: 4405.877,
    battle_h: 264352.62,
    exp_m: 1065,
    exp_h: 63900,
    dust_m: 0.7115,
    dust_h: 42.69,
    core_h: 61.9005
  },
  {
    level: 261,
    credit_m: 425.6154,
    credit_h: 25536.924,
    battle_m: 4407.587,
    battle_h: 264455.22,
    exp_m: 1070,
    exp_h: 64200,
    dust_m: 0.7123,
    dust_h: 42.738,
    core_h: 61.9701
  },
  {
    level: 262,
    credit_m: 426.1311,
    credit_h: 25567.866,
    battle_m: 4409.297,
    battle_h: 264557.82,
    exp_m: 1074,
    exp_h: 64440,
    dust_m: 0.7132,
    dust_h: 42.792,
    core_h: 62.0484
  },
  {
    level: 263,
    credit_m: 426.6468,
    credit_h: 25598.808,
    battle_m: 4411.007,
    battle_h: 264660.42,
    exp_m: 1079,
    exp_h: 64740,
    dust_m: 0.714,
    dust_h: 42.84,
    core_h: 62.118
  },
  {
    level: 264,
    credit_m: 427.1622,
    credit_h: 25629.732,
    battle_m: 4412.718,
    battle_h: 264763.08,
    exp_m: 1084,
    exp_h: 65040,
    dust_m: 0.7148,
    dust_h: 42.888,
    core_h: 62.1876
  },
  {
    level: 265,
    credit_m: 427.6773,
    credit_h: 25660.638,
    battle_m: 4414.429,
    battle_h: 264865.74,
    exp_m: 1088,
    exp_h: 65280,
    dust_m: 0.7157,
    dust_h: 42.942,
    core_h: 62.2659
  },
  {
    level: 266,
    credit_m: 428.1924,
    credit_h: 25691.544,
    battle_m: 4416.139,
    battle_h: 264968.34,
    exp_m: 1093,
    exp_h: 65580,
    dust_m: 0.7165,
    dust_h: 42.99,
    core_h: 62.3355
  },
  {
    level: 267,
    credit_m: 428.7072,
    credit_h: 25722.432,
    battle_m: 4417.85,
    battle_h: 265071,
    exp_m: 1097,
    exp_h: 65820,
    dust_m: 0.7173,
    dust_h: 43.038,
    core_h: 62.4051
  },
  {
    level: 268,
    credit_m: 429.222,
    credit_h: 25753.32,
    battle_m: 4419.561,
    battle_h: 265173.66,
    exp_m: 1102,
    exp_h: 66120,
    dust_m: 0.7182,
    dust_h: 43.092,
    core_h: 62.4834
  },
  {
    level: 269,
    credit_m: 429.7365,
    credit_h: 25784.19,
    battle_m: 4421.272,
    battle_h: 265276.32,
    exp_m: 1107,
    exp_h: 66420,
    dust_m: 0.719,
    dust_h: 43.14,
    core_h: 62.553
  },
  {
    level: 270,
    credit_m: 430.2507,
    credit_h: 25815.042,
    battle_m: 4422.983,
    battle_h: 265378.98,
    exp_m: 1111,
    exp_h: 66660,
    dust_m: 0.7198,
    dust_h: 43.188,
    core_h: 62.6226
  },
  {
    level: 271,
    credit_m: 430.7646,
    credit_h: 25845.876,
    battle_m: 4424.695,
    battle_h: 265481.7,
    exp_m: 1116,
    exp_h: 66960,
    dust_m: 0.7206,
    dust_h: 43.236,
    core_h: 62.6922
  },
  {
    level: 272,
    credit_m: 431.2785,
    credit_h: 25876.71,
    battle_m: 4426.406,
    battle_h: 265584.36,
    exp_m: 1120,
    exp_h: 67200,
    dust_m: 0.7215,
    dust_h: 43.29,
    core_h: 62.7705
  },
  {
    level: 273,
    credit_m: 431.7924,
    credit_h: 25907.544,
    battle_m: 4428.118,
    battle_h: 265687.08,
    exp_m: 1125,
    exp_h: 67500,
    dust_m: 0.7223,
    dust_h: 43.338,
    core_h: 62.8401
  },
  {
    level: 274,
    credit_m: 432.3057,
    credit_h: 25938.342,
    battle_m: 4429.829,
    battle_h: 265789.74,
    exp_m: 1130,
    exp_h: 67800,
    dust_m: 0.7231,
    dust_h: 43.386,
    core_h: 62.9097
  },
  {
    level: 275,
    credit_m: 432.819,
    credit_h: 25969.14,
    battle_m: 4431.541,
    battle_h: 265892.46,
    exp_m: 1134,
    exp_h: 68040,
    dust_m: 0.7239,
    dust_h: 43.434,
    core_h: 62.9793
  },
  {
    level: 276,
    credit_m: 433.3323,
    credit_h: 25999.938,
    battle_m: 4433.253,
    battle_h: 265995.18,
    exp_m: 1139,
    exp_h: 68340,
    dust_m: 0.7247,
    dust_h: 43.482,
    core_h: 63.0489
  },
  {
    level: 277,
    credit_m: 433.8453,
    credit_h: 26030.718,
    battle_m: 4434.965,
    battle_h: 266097.9,
    exp_m: 1144,
    exp_h: 68640,
    dust_m: 0.7255,
    dust_h: 43.53,
    core_h: 63.1185
  },
  {
    level: 278,
    credit_m: 434.358,
    credit_h: 26061.48,
    battle_m: 4436.678,
    battle_h: 266200.68,
    exp_m: 1148,
    exp_h: 68880,
    dust_m: 0.7264,
    dust_h: 43.584,
    core_h: 63.1968
  },
  {
    level: 279,
    credit_m: 434.8704,
    credit_h: 26092.224,
    battle_m: 4438.39,
    battle_h: 266303.4,
    exp_m: 1153,
    exp_h: 69180,
    dust_m: 0.7272,
    dust_h: 43.632,
    core_h: 63.2664
  },
  {
    level: 280,
    credit_m: 435.3828,
    credit_h: 26122.968,
    battle_m: 4440.102,
    battle_h: 266406.12,
    exp_m: 1157,
    exp_h: 69420,
    dust_m: 0.728,
    dust_h: 43.68,
    core_h: 63.336
  },
  {
    level: 281,
    credit_m: 435.8952,
    credit_h: 26153.712,
    battle_m: 4441.815,
    battle_h: 266508.9,
    exp_m: 1162,
    exp_h: 69720,
    dust_m: 0.7288,
    dust_h: 43.728,
    core_h: 63.4056
  },
  {
    level: 282,
    credit_m: 436.4073,
    credit_h: 26184.438,
    battle_m: 4443.527,
    battle_h: 266611.62,
    exp_m: 1167,
    exp_h: 70020,
    dust_m: 0.7296,
    dust_h: 43.776,
    core_h: 63.4752
  },
  {
    level: 283,
    credit_m: 437.4705,
    credit_h: 26248.23,
    battle_m: 4454.573,
    battle_h: 267274.38,
    exp_m: 1171,
    exp_h: 70260,
    dust_m: 0.7313,
    dust_h: 43.878,
    core_h: 63.6231
  },
  {
    level: 284,
    credit_m: 438.2892,
    credit_h: 26297.352,
    battle_m: 4465.691,
    battle_h: 267941.46,
    exp_m: 1176,
    exp_h: 70560,
    dust_m: 0.7329,
    dust_h: 43.974,
    core_h: 63.7623
  },
  {
    level: 285,
    credit_m: 439.1076,
    credit_h: 26346.456,
    battle_m: 4476.802,
    battle_h: 268608.12,
    exp_m: 1181,
    exp_h: 70860,
    dust_m: 0.7345,
    dust_h: 44.07,
    core_h: 63.9015
  },
  {
    level: 286,
    credit_m: 439.9257,
    credit_h: 26395.542,
    battle_m: 4487.905,
    battle_h: 269274.3,
    exp_m: 1185,
    exp_h: 71100,
    dust_m: 0.7361,
    dust_h: 44.166,
    core_h: 64.0407
  },
  {
    level: 287,
    credit_m: 440.7435,
    credit_h: 26444.61,
    battle_m: 4499,
    battle_h: 269940,
    exp_m: 1190,
    exp_h: 71400,
    dust_m: 0.7377,
    dust_h: 44.262,
    core_h: 64.1799
  },
  {
    level: 288,
    credit_m: 441.561,
    credit_h: 26493.66,
    battle_m: 4510.088,
    battle_h: 270605.28,
    exp_m: 1195,
    exp_h: 71700,
    dust_m: 0.7393,
    dust_h: 44.358,
    core_h: 64.3191
  },
  {
    level: 289,
    credit_m: 442.3782,
    credit_h: 26542.692,
    battle_m: 4521.167,
    battle_h: 271270.02,
    exp_m: 1199,
    exp_h: 71940,
    dust_m: 0.7409,
    dust_h: 44.454,
    core_h: 64.4583
  },
  {
    level: 290,
    credit_m: 443.1951,
    credit_h: 26591.706,
    battle_m: 4532.24,
    battle_h: 271934.4,
    exp_m: 1204,
    exp_h: 72240,
    dust_m: 0.7425,
    dust_h: 44.55,
    core_h: 64.5975
  },
  {
    level: 291,
    credit_m: 444.012,
    credit_h: 26640.72,
    battle_m: 4543.304,
    battle_h: 272598.24,
    exp_m: 1209,
    exp_h: 72540,
    dust_m: 0.7441,
    dust_h: 44.646,
    core_h: 64.7367
  },
  {
    level: 292,
    credit_m: 444.8283,
    credit_h: 26689.698,
    battle_m: 4554.361,
    battle_h: 273261.66,
    exp_m: 1213,
    exp_h: 72780,
    dust_m: 0.7457,
    dust_h: 44.742,
    core_h: 64.8759
  },
  {
    level: 293,
    credit_m: 445.6443,
    credit_h: 26738.658,
    battle_m: 4565.41,
    battle_h: 273924.6,
    exp_m: 1218,
    exp_h: 73080,
    dust_m: 0.7473,
    dust_h: 44.838,
    core_h: 65.0151
  },
  {
    level: 294,
    credit_m: 446.4603,
    credit_h: 26787.618,
    battle_m: 4576.452,
    battle_h: 274587.12,
    exp_m: 1223,
    exp_h: 73380,
    dust_m: 0.7489,
    dust_h: 44.934,
    core_h: 65.1543
  },
  {
    level: 295,
    credit_m: 447.2757,
    credit_h: 26836.542,
    battle_m: 4587.486,
    battle_h: 275249.16,
    exp_m: 1227,
    exp_h: 73620,
    dust_m: 0.7505,
    dust_h: 45.03,
    core_h: 65.2935
  },
  {
    level: 296,
    credit_m: 448.0911,
    credit_h: 26885.466,
    battle_m: 4598.513,
    battle_h: 275910.78,
    exp_m: 1232,
    exp_h: 73920,
    dust_m: 0.752,
    dust_h: 45.12,
    core_h: 65.424
  },
  {
    level: 297,
    credit_m: 448.9059,
    credit_h: 26934.354,
    battle_m: 4609.532,
    battle_h: 276571.92,
    exp_m: 1237,
    exp_h: 74220,
    dust_m: 0.7536,
    dust_h: 45.216,
    core_h: 65.5632
  },
  {
    level: 298,
    credit_m: 449.7207,
    credit_h: 26983.242,
    battle_m: 4620.544,
    battle_h: 277232.64,
    exp_m: 1241,
    exp_h: 74460,
    dust_m: 0.7552,
    dust_h: 45.312,
    core_h: 65.7024
  },
  {
    level: 299,
    credit_m: 450.5352,
    credit_h: 27032.112,
    battle_m: 4631.548,
    battle_h: 277892.88,
    exp_m: 1246,
    exp_h: 74760,
    dust_m: 0.7568,
    dust_h: 45.408,
    core_h: 65.8416
  },
  {
    level: 300,
    credit_m: 451.3494,
    credit_h: 27080.964,
    battle_m: 4642.545,
    battle_h: 278552.7,
    exp_m: 1251,
    exp_h: 75060,
    dust_m: 0.7583,
    dust_h: 45.498,
    core_h: 65.9721
  },
  {
    level: 301,
    credit_m: 452.1633,
    credit_h: 27129.798,
    battle_m: 4653.535,
    battle_h: 279212.1,
    exp_m: 1254.9999,
    exp_h: 75299.994,
    dust_m: 0.7599,
    dust_h: 45.594,
    core_h: 66.1113
  },
  {
    level: 302,
    credit_m: 452.9769,
    credit_h: 27178.614,
    battle_m: 4664.518,
    battle_h: 279871.08,
    exp_m: 1260,
    exp_h: 75600,
    dust_m: 0.7615,
    dust_h: 45.69,
    core_h: 66.2505
  },
  {
    level: 303,
    credit_m: 453.7902,
    credit_h: 27227.412,
    battle_m: 4675.493,
    battle_h: 280529.58,
    exp_m: 1264.9998,
    exp_h: 75899.988,
    dust_m: 0.763,
    dust_h: 45.78,
    core_h: 66.381
  },
  {
    level: 304,
    credit_m: 454.6035,
    credit_h: 27276.21,
    battle_m: 4686.461,
    battle_h: 281187.66,
    exp_m: 1269,
    exp_h: 76140,
    dust_m: 0.7646,
    dust_h: 45.876,
    core_h: 66.5202
  },
  {
    level: 305,
    credit_m: 455.4162,
    credit_h: 27324.972,
    battle_m: 4697.422,
    battle_h: 281845.32,
    exp_m: 1273.9998,
    exp_h: 76439.988,
    dust_m: 0.7661,
    dust_h: 45.966,
    core_h: 66.6507
  },
  {
    level: 306,
    credit_m: 456.2289,
    credit_h: 27373.734,
    battle_m: 4708.375,
    battle_h: 282502.5,
    exp_m: 1278.9999,
    exp_h: 76739.994,
    dust_m: 0.7677,
    dust_h: 46.062,
    core_h: 66.7899
  },
  {
    level: 307,
    credit_m: 457.0413,
    credit_h: 27422.478,
    battle_m: 4719.322,
    battle_h: 283159.32,
    exp_m: 1282.9998,
    exp_h: 76979.988,
    dust_m: 0.7692,
    dust_h: 46.152,
    core_h: 66.9204
  },
  {
    level: 308,
    credit_m: 457.8531,
    credit_h: 27471.186,
    battle_m: 4730.261,
    battle_h: 283815.66,
    exp_m: 1287.9999,
    exp_h: 77279.994,
    dust_m: 0.7708,
    dust_h: 46.248,
    core_h: 67.0596
  },
  {
    level: 309,
    credit_m: 458.6649,
    credit_h: 27519.894,
    battle_m: 4741.193,
    battle_h: 284471.58,
    exp_m: 1293,
    exp_h: 77580,
    dust_m: 0.7723,
    dust_h: 46.338,
    core_h: 67.1901
  },
  {
    level: 310,
    credit_m: 459.4764,
    credit_h: 27568.584,
    battle_m: 4752.118,
    battle_h: 285127.08,
    exp_m: 1296.9999,
    exp_h: 77819.994,
    dust_m: 0.7739,
    dust_h: 46.434,
    core_h: 67.3293
  },
  {
    level: 311,
    credit_m: 460.2876,
    credit_h: 27617.256,
    battle_m: 4763.036,
    battle_h: 285782.16,
    exp_m: 1302,
    exp_h: 78120,
    dust_m: 0.7754,
    dust_h: 46.524,
    core_h: 67.4598
  },
  {
    level: 312,
    credit_m: 461.0988,
    credit_h: 27665.928,
    battle_m: 4773.947,
    battle_h: 286436.82,
    exp_m: 1306.9998,
    exp_h: 78419.988,
    dust_m: 0.777,
    dust_h: 46.62,
    core_h: 67.599
  },
  {
    level: 313,
    credit_m: 461.9094,
    credit_h: 27714.564,
    battle_m: 4784.852,
    battle_h: 287091.12,
    exp_m: 1311.9999,
    exp_h: 78719.994,
    dust_m: 0.7785,
    dust_h: 46.71,
    core_h: 67.7295
  },
  {
    level: 314,
    credit_m: 462.72,
    credit_h: 27763.2,
    battle_m: 4795.749,
    battle_h: 287744.94,
    exp_m: 1315.9998,
    exp_h: 78959.988,
    dust_m: 0.78,
    dust_h: 46.8,
    core_h: 67.86
  },
  {
    level: 315,
    credit_m: 463.5303,
    credit_h: 27811.818,
    battle_m: 4806.639,
    battle_h: 288398.34,
    exp_m: 1320.9999,
    exp_h: 79259.994,
    dust_m: 0.7816,
    dust_h: 46.896,
    core_h: 67.9992
  },
  {
    level: 316,
    credit_m: 464.34,
    credit_h: 27860.4,
    battle_m: 4817.522,
    battle_h: 289051.32,
    exp_m: 1326,
    exp_h: 79560,
    dust_m: 0.7831,
    dust_h: 46.986,
    core_h: 68.1297
  },
  {
    level: 317,
    credit_m: 465.1497,
    credit_h: 27908.982,
    battle_m: 4828.398,
    battle_h: 289703.88,
    exp_m: 1329.9999,
    exp_h: 79799.994,
    dust_m: 0.7846,
    dust_h: 47.076,
    core_h: 68.2602
  },
  {
    level: 318,
    credit_m: 465.9594,
    credit_h: 27957.564,
    battle_m: 4839.268,
    battle_h: 290356.08,
    exp_m: 1335,
    exp_h: 80100,
    dust_m: 0.7861,
    dust_h: 47.166,
    core_h: 68.3907
  },
  {
    level: 319,
    credit_m: 466.7685,
    credit_h: 28006.11,
    battle_m: 4850.131,
    battle_h: 291007.86,
    exp_m: 1339.9998,
    exp_h: 80399.988,
    dust_m: 0.7877,
    dust_h: 47.262,
    core_h: 68.5299
  },
  {
    level: 320,
    credit_m: 467.5773,
    credit_h: 28054.638,
    battle_m: 4860.986,
    battle_h: 291659.16,
    exp_m: 1344.9999,
    exp_h: 80699.994,
    dust_m: 0.7892,
    dust_h: 47.352,
    core_h: 68.6604
  },
  {
    level: 321,
    credit_m: 468.3861,
    credit_h: 28103.166,
    battle_m: 4871.836,
    battle_h: 292310.16,
    exp_m: 1348.9998,
    exp_h: 80939.988,
    dust_m: 0.7907,
    dust_h: 47.442,
    core_h: 68.7909
  },
  {
    level: 322,
    credit_m: 469.1946,
    credit_h: 28151.676,
    battle_m: 4882.678,
    battle_h: 292960.68,
    exp_m: 1353.9999,
    exp_h: 81239.994,
    dust_m: 0.7922,
    dust_h: 47.532,
    core_h: 68.9214
  },
  {
    level: 323,
    credit_m: 470.0028,
    credit_h: 28200.168,
    battle_m: 4893.513,
    battle_h: 293610.78,
    exp_m: 1359,
    exp_h: 81540,
    dust_m: 0.7937,
    dust_h: 47.622,
    core_h: 69.0519
  },
  {
    level: 324,
    credit_m: 470.8107,
    credit_h: 28248.642,
    battle_m: 4904.342,
    battle_h: 294260.52,
    exp_m: 1363.9998,
    exp_h: 81839.988,
    dust_m: 0.7953,
    dust_h: 47.718,
    core_h: 69.1911
  },
  {
    level: 325,
    credit_m: 471.6183,
    credit_h: 28297.098,
    battle_m: 4915.165,
    battle_h: 294909.9,
    exp_m: 1368,
    exp_h: 82080,
    dust_m: 0.7968,
    dust_h: 47.808,
    core_h: 69.3216
  },
  {
    level: 326,
    credit_m: 472.4256,
    credit_h: 28345.536,
    battle_m: 4925.98,
    battle_h: 295558.8,
    exp_m: 1372.9998,
    exp_h: 82379.988,
    dust_m: 0.7983,
    dust_h: 47.898,
    core_h: 69.4521
  },
  {
    level: 327,
    credit_m: 473.2329,
    credit_h: 28393.974,
    battle_m: 4936.789,
    battle_h: 296207.34,
    exp_m: 1377.9999,
    exp_h: 82679.994,
    dust_m: 0.7998,
    dust_h: 47.988,
    core_h: 69.5826
  },
  {
    level: 328,
    credit_m: 474.0399,
    credit_h: 28442.394,
    battle_m: 4947.591,
    battle_h: 296855.46,
    exp_m: 1383,
    exp_h: 82980,
    dust_m: 0.8013,
    dust_h: 48.078,
    core_h: 69.7131
  },
  {
    level: 329,
    credit_m: 474.8466,
    credit_h: 28490.796,
    battle_m: 4958.387,
    battle_h: 297503.22,
    exp_m: 1386.9999,
    exp_h: 83219.994,
    dust_m: 0.8028,
    dust_h: 48.168,
    core_h: 69.8436
  },
  {
    level: 330,
    credit_m: 475.653,
    credit_h: 28539.18,
    battle_m: 4969.176,
    battle_h: 298150.56,
    exp_m: 1392,
    exp_h: 83520,
    dust_m: 0.8043,
    dust_h: 48.258,
    core_h: 69.9741
  },
  {
    level: 331,
    credit_m: 476.4591,
    credit_h: 28587.546,
    battle_m: 4979.959,
    battle_h: 298797.54,
    exp_m: 1396.9998,
    exp_h: 83819.988,
    dust_m: 0.8058,
    dust_h: 48.348,
    core_h: 70.1046
  },
  {
    level: 332,
    credit_m: 477.2652,
    credit_h: 28635.912,
    battle_m: 4990.735,
    battle_h: 299444.1,
    exp_m: 1401.9999,
    exp_h: 84119.994,
    dust_m: 0.8073,
    dust_h: 48.438,
    core_h: 70.2351
  },
  {
    level: 333,
    credit_m: 478.071,
    credit_h: 28684.26,
    battle_m: 5001.504,
    battle_h: 300090.24,
    exp_m: 1405.9998,
    exp_h: 84359.988,
    dust_m: 0.8088,
    dust_h: 48.528,
    core_h: 70.3656
  },
  {
    level: 334,
    credit_m: 478.8765,
    credit_h: 28732.59,
    battle_m: 5012.268,
    battle_h: 300736.08,
    exp_m: 1410.9999,
    exp_h: 84659.994,
    dust_m: 0.8103,
    dust_h: 48.618,
    core_h: 70.4961
  },
  {
    level: 335,
    credit_m: 479.6817,
    credit_h: 28780.902,
    battle_m: 5023.024,
    battle_h: 301381.44,
    exp_m: 1416,
    exp_h: 84960,
    dust_m: 0.8118,
    dust_h: 48.708,
    core_h: 70.6266
  },
  {
    level: 336,
    credit_m: 480.4866,
    credit_h: 28829.196,
    battle_m: 5033.774,
    battle_h: 302026.44,
    exp_m: 1420.9998,
    exp_h: 85259.988,
    dust_m: 0.8133,
    dust_h: 48.798,
    core_h: 70.7571
  },
  {
    level: 337,
    credit_m: 481.2915,
    credit_h: 28877.49,
    battle_m: 5044.518,
    battle_h: 302671.08,
    exp_m: 1425,
    exp_h: 85500,
    dust_m: 0.8147,
    dust_h: 48.882,
    core_h: 70.8789
  },
  {
    level: 338,
    credit_m: 482.0958,
    credit_h: 28925.748,
    battle_m: 5055.256,
    battle_h: 303315.36,
    exp_m: 1429.9998,
    exp_h: 85799.988,
    dust_m: 0.8162,
    dust_h: 48.972,
    core_h: 71.0094
  },
  {
    level: 339,
    credit_m: 482.9001,
    credit_h: 28974.006,
    battle_m: 5065.987,
    battle_h: 303959.22,
    exp_m: 1434.9999,
    exp_h: 86099.994,
    dust_m: 0.8177,
    dust_h: 49.062,
    core_h: 71.1399
  },
  {
    level: 340,
    credit_m: 483.7041,
    credit_h: 29022.246,
    battle_m: 5076.712,
    battle_h: 304602.72,
    exp_m: 1440,
    exp_h: 86400,
    dust_m: 0.8192,
    dust_h: 49.152,
    core_h: 71.2704
  },
  {
    level: 341,
    credit_m: 484.5081,
    credit_h: 29070.486,
    battle_m: 5087.431,
    battle_h: 305245.86,
    exp_m: 1443.9999,
    exp_h: 86639.994,
    dust_m: 0.8207,
    dust_h: 49.242,
    core_h: 71.4009
  },
  {
    level: 342,
    credit_m: 485.3115,
    credit_h: 29118.69,
    battle_m: 5098.143,
    battle_h: 305888.58,
    exp_m: 1449,
    exp_h: 86940,
    dust_m: 0.8221,
    dust_h: 49.326,
    core_h: 71.5227
  },
  {
    level: 343,
    credit_m: 486.1149,
    credit_h: 29166.894,
    battle_m: 5108.849,
    battle_h: 306530.94,
    exp_m: 1453.9998,
    exp_h: 87239.988,
    dust_m: 0.8236,
    dust_h: 49.416,
    core_h: 71.6532
  },
  {
    level: 344,
    credit_m: 486.918,
    credit_h: 29215.08,
    battle_m: 5119.548,
    battle_h: 307172.88,
    exp_m: 1458.9999,
    exp_h: 87539.994,
    dust_m: 0.8251,
    dust_h: 49.506,
    core_h: 71.7837
  },
  {
    level: 345,
    credit_m: 487.7208,
    credit_h: 29263.248,
    battle_m: 5130.242,
    battle_h: 307814.52,
    exp_m: 1462.9998,
    exp_h: 87779.988,
    dust_m: 0.8266,
    dust_h: 49.596,
    core_h: 71.9142
  },
  {
    level: 346,
    credit_m: 488.5236,
    credit_h: 29311.416,
    battle_m: 5140.929,
    battle_h: 308455.74,
    exp_m: 1467.9999,
    exp_h: 88079.994,
    dust_m: 0.828,
    dust_h: 49.68,
    core_h: 72.036
  },
  {
    level: 347,
    credit_m: 489.3258,
    credit_h: 29359.548,
    battle_m: 5151.611,
    battle_h: 309096.66,
    exp_m: 1473,
    exp_h: 88380,
    dust_m: 0.8295,
    dust_h: 49.77,
    core_h: 72.1665
  },
  {
    level: 348,
    credit_m: 490.128,
    credit_h: 29407.68,
    battle_m: 5162.285,
    battle_h: 309737.1,
    exp_m: 1477.9998,
    exp_h: 88679.988,
    dust_m: 0.831,
    dust_h: 49.86,
    core_h: 72.297
  },
  {
    level: 349,
    credit_m: 490.9299,
    credit_h: 29455.794,
    battle_m: 5172.954,
    battle_h: 310377.24,
    exp_m: 1482.9999,
    exp_h: 88979.994,
    dust_m: 0.8324,
    dust_h: 49.944,
    core_h: 72.4188
  },
  {
    level: 350,
    credit_m: 491.7318,
    credit_h: 29503.908,
    battle_m: 5183.617,
    battle_h: 311017.02,
    exp_m: 1486.9998,
    exp_h: 89219.988,
    dust_m: 0.8339,
    dust_h: 50.034,
    core_h: 72.5493
  },
  {
    level: 351,
    credit_m: 492.5331,
    credit_h: 29551.986,
    battle_m: 5194.274,
    battle_h: 311656.44,
    exp_m: 1491.9999,
    exp_h: 89519.994,
    dust_m: 0.8353,
    dust_h: 50.118,
    core_h: 72.6711
  },
  {
    level: 352,
    credit_m: 493.3344,
    credit_h: 29600.064,
    battle_m: 5204.924,
    battle_h: 312295.44,
    exp_m: 1497,
    exp_h: 89820,
    dust_m: 0.8368,
    dust_h: 50.208,
    core_h: 72.8016
  },
  {
    level: 353,
    credit_m: 494.1354,
    credit_h: 29648.124,
    battle_m: 5215.569,
    battle_h: 312934.14,
    exp_m: 1501.9998,
    exp_h: 90119.988,
    dust_m: 0.8382,
    dust_h: 50.292,
    core_h: 72.9234
  },
  {
    level: 354,
    credit_m: 494.9364,
    credit_h: 29696.184,
    battle_m: 5226.207,
    battle_h: 313572.42,
    exp_m: 1506.9999,
    exp_h: 90419.994,
    dust_m: 0.8397,
    dust_h: 50.382,
    core_h: 73.0539
  },
  {
    level: 355,
    credit_m: 495.7368,
    credit_h: 29744.208,
    battle_m: 5236.84,
    battle_h: 314210.4,
    exp_m: 1510.9998,
    exp_h: 90659.988,
    dust_m: 0.8411,
    dust_h: 50.466,
    core_h: 73.1757
  },
  {
    level: 356,
    credit_m: 496.5372,
    credit_h: 29792.232,
    battle_m: 5247.466,
    battle_h: 314847.96,
    exp_m: 1515.9999,
    exp_h: 90959.994,
    dust_m: 0.8426,
    dust_h: 50.556,
    core_h: 73.3062
  },
  {
    level: 357,
    credit_m: 497.3373,
    credit_h: 29840.238,
    battle_m: 5258.087,
    battle_h: 315485.22,
    exp_m: 1521,
    exp_h: 91260,
    dust_m: 0.844,
    dust_h: 50.64,
    core_h: 73.428
  },
  {
    level: 358,
    credit_m: 498.1371,
    credit_h: 29888.226,
    battle_m: 5268.701,
    battle_h: 316122.06,
    exp_m: 1525.9998,
    exp_h: 91559.988,
    dust_m: 0.8455,
    dust_h: 50.73,
    core_h: 73.5585
  },
  {
    level: 359,
    credit_m: 498.9369,
    credit_h: 29936.214,
    battle_m: 5279.31,
    battle_h: 316758.6,
    exp_m: 1530.9999,
    exp_h: 91859.994,
    dust_m: 0.8469,
    dust_h: 50.814,
    core_h: 73.6803
  },
  {
    level: 360,
    credit_m: 499.7364,
    credit_h: 29984.184,
    battle_m: 5289.913,
    battle_h: 317394.78,
    exp_m: 1534.9998,
    exp_h: 92099.988,
    dust_m: 0.8484,
    dust_h: 50.904,
    core_h: 73.8108
  },
  {
    level: 361,
    credit_m: 500.5356,
    credit_h: 30032.136,
    battle_m: 5300.51,
    battle_h: 318030.6,
    exp_m: 1539.9999,
    exp_h: 92399.994,
    dust_m: 0.8498,
    dust_h: 50.988,
    core_h: 73.9326
  },
  {
    level: 362,
    credit_m: 501.3345,
    credit_h: 30080.07,
    battle_m: 5311.101,
    battle_h: 318666.06,
    exp_m: 1545,
    exp_h: 92700,
    dust_m: 0.8513,
    dust_h: 51.078,
    core_h: 74.0631
  },
  {
    level: 363,
    credit_m: 502.1334,
    credit_h: 30128.004,
    battle_m: 5321.686,
    battle_h: 319301.16,
    exp_m: 1549.9998,
    exp_h: 92999.988,
    dust_m: 0.8527,
    dust_h: 51.162,
    core_h: 74.1849
  },
  {
    level: 364,
    credit_m: 502.932,
    credit_h: 30175.92,
    battle_m: 5332.265,
    battle_h: 319935.9,
    exp_m: 1554.9999,
    exp_h: 93299.994,
    dust_m: 0.8541,
    dust_h: 51.246,
    core_h: 74.3067
  },
  {
    level: 365,
    credit_m: 503.7303,
    credit_h: 30223.818,
    battle_m: 5342.839,
    battle_h: 320570.34,
    exp_m: 1558.9998,
    exp_h: 93539.988,
    dust_m: 0.8556,
    dust_h: 51.336,
    core_h: 74.4372
  },
  {
    level: 366,
    credit_m: 504.5283,
    credit_h: 30271.698,
    battle_m: 5353.406,
    battle_h: 321204.36,
    exp_m: 1563.9999,
    exp_h: 93839.994,
    dust_m: 0.857,
    dust_h: 51.42,
    core_h: 74.559
  },
  {
    level: 367,
    credit_m: 505.3263,
    credit_h: 30319.578,
    battle_m: 5363.968,
    battle_h: 321838.08,
    exp_m: 1569,
    exp_h: 94140,
    dust_m: 0.8584,
    dust_h: 51.504,
    core_h: 74.6808
  },
  {
    level: 368,
    credit_m: 506.124,
    credit_h: 30367.44,
    battle_m: 5374.525,
    battle_h: 322471.5,
    exp_m: 1573.9998,
    exp_h: 94439.988,
    dust_m: 0.8598,
    dust_h: 51.588,
    core_h: 74.8026
  },
  {
    level: 369,
    credit_m: 506.9214,
    credit_h: 30415.284,
    battle_m: 5385.075,
    battle_h: 323104.5,
    exp_m: 1578.9999,
    exp_h: 94739.994,
    dust_m: 0.8613,
    dust_h: 51.678,
    core_h: 74.9331
  },
  {
    level: 370,
    credit_m: 507.7188,
    credit_h: 30463.128,
    battle_m: 5395.62,
    battle_h: 323737.2,
    exp_m: 1584,
    exp_h: 95040,
    dust_m: 0.8627,
    dust_h: 51.762,
    core_h: 75.0549
  },
  {
    level: 371,
    credit_m: 508.5159,
    credit_h: 30510.954,
    battle_m: 5406.159,
    battle_h: 324369.54,
    exp_m: 1587.9999,
    exp_h: 95279.994,
    dust_m: 0.8641,
    dust_h: 51.846,
    core_h: 75.1767
  },
  {
    level: 372,
    credit_m: 509.3127,
    credit_h: 30558.762,
    battle_m: 5416.692,
    battle_h: 325001.52,
    exp_m: 1593,
    exp_h: 95580,
    dust_m: 0.8655,
    dust_h: 51.93,
    core_h: 75.2985
  },
  {
    level: 373,
    credit_m: 510.1092,
    credit_h: 30606.552,
    battle_m: 5427.22,
    battle_h: 325633.2,
    exp_m: 1597.9998,
    exp_h: 95879.988,
    dust_m: 0.867,
    dust_h: 52.02,
    core_h: 75.429
  },
  {
    level: 374,
    credit_m: 510.9057,
    credit_h: 30654.342,
    battle_m: 5437.742,
    battle_h: 326264.52,
    exp_m: 1602.9999,
    exp_h: 96179.994,
    dust_m: 0.8684,
    dust_h: 52.104,
    core_h: 75.5508
  },
  {
    level: 375,
    credit_m: 511.7019,
    credit_h: 30702.114,
    battle_m: 5448.258,
    battle_h: 326895.48,
    exp_m: 1608,
    exp_h: 96480,
    dust_m: 0.8698,
    dust_h: 52.188,
    core_h: 75.6726
  },
  {
    level: 376,
    credit_m: 512.4978,
    credit_h: 30749.868,
    battle_m: 5458.769,
    battle_h: 327526.14,
    exp_m: 1612.9998,
    exp_h: 96779.988,
    dust_m: 0.8712,
    dust_h: 52.272,
    core_h: 75.7944
  },
  {
    level: 377,
    credit_m: 513.2937,
    credit_h: 30797.622,
    battle_m: 5469.274,
    battle_h: 328156.44,
    exp_m: 1617,
    exp_h: 97020,
    dust_m: 0.8726,
    dust_h: 52.356,
    core_h: 75.9162
  },
  {
    level: 378,
    credit_m: 514.0893,
    credit_h: 30845.358,
    battle_m: 5479.774,
    battle_h: 328786.44,
    exp_m: 1621.9998,
    exp_h: 97319.988,
    dust_m: 0.874,
    dust_h: 52.44,
    core_h: 76.038
  },
  {
    level: 379,
    credit_m: 514.8846,
    credit_h: 30893.076,
    battle_m: 5490.268,
    battle_h: 329416.08,
    exp_m: 1626.9999,
    exp_h: 97619.994,
    dust_m: 0.8754,
    dust_h: 52.524,
    core_h: 76.1598
  },
  {
    level: 380,
    credit_m: 515.6796,
    credit_h: 30940.776,
    battle_m: 5500.757,
    battle_h: 330045.42,
    exp_m: 1632,
    exp_h: 97920,
    dust_m: 0.8768,
    dust_h: 52.608,
    core_h: 76.2816
  },
  {
    level: 381,
    credit_m: 516.4746,
    credit_h: 30988.476,
    battle_m: 5511.24,
    battle_h: 330674.4,
    exp_m: 1636.9998,
    exp_h: 98219.988,
    dust_m: 0.8782,
    dust_h: 52.692,
    core_h: 76.4034
  },
  {
    level: 382,
    credit_m: 517.2693,
    credit_h: 31036.158,
    battle_m: 5521.717,
    battle_h: 331303.02,
    exp_m: 1641.9999,
    exp_h: 98519.994,
    dust_m: 0.8796,
    dust_h: 52.776,
    core_h: 76.5252
  },
  {
    level: 383,
    credit_m: 518.0637,
    credit_h: 31083.822,
    battle_m: 5532.189,
    battle_h: 331931.34,
    exp_m: 1647,
    exp_h: 98820,
    dust_m: 0.881,
    dust_h: 52.86,
    core_h: 76.647
  },
  {
    level: 384,
    credit_m: 518.8581,
    credit_h: 31131.486,
    battle_m: 5542.656,
    battle_h: 332559.36,
    exp_m: 1650.9999,
    exp_h: 99059.994,
    dust_m: 0.8824,
    dust_h: 52.944,
    core_h: 76.7688
  },
  {
    level: 385,
    credit_m: 519.6522,
    credit_h: 31179.132,
    battle_m: 5553.117,
    battle_h: 333187.02,
    exp_m: 1656,
    exp_h: 99360,
    dust_m: 0.8838,
    dust_h: 53.028,
    core_h: 76.8906
  },
  {
    level: 386,
    credit_m: 520.446,
    credit_h: 31226.76,
    battle_m: 5563.573,
    battle_h: 333814.38,
    exp_m: 1660.9998,
    exp_h: 99659.988,
    dust_m: 0.8852,
    dust_h: 53.112,
    core_h: 77.0124
  },
  {
    level: 387,
    credit_m: 521.2398,
    credit_h: 31274.388,
    battle_m: 5574.023,
    battle_h: 334441.38,
    exp_m: 1665.9999,
    exp_h: 99959.994,
    dust_m: 0.8866,
    dust_h: 53.196,
    core_h: 77.1342
  },
  {
    level: 388,
    credit_m: 522.0333,
    credit_h: 31321.998,
    battle_m: 5584.468,
    battle_h: 335068.08,
    exp_m: 1671,
    exp_h: 100260,
    dust_m: 0.888,
    dust_h: 53.28,
    core_h: 77.256
  },
  {
    level: 389,
    credit_m: 522.8265,
    credit_h: 31369.59,
    battle_m: 5594.907,
    battle_h: 335694.42,
    exp_m: 1675.9998,
    exp_h: 100559.988,
    dust_m: 0.8894,
    dust_h: 53.364,
    core_h: 77.3778
  },
  {
    level: 390,
    credit_m: 523.6197,
    credit_h: 31417.182,
    battle_m: 5605.342,
    battle_h: 336320.52,
    exp_m: 1680.9999,
    exp_h: 100859.994,
    dust_m: 0.8908,
    dust_h: 53.448,
    core_h: 77.4996
  },
  {
    level: 391,
    credit_m: 524.4126,
    credit_h: 31464.756,
    battle_m: 5615.771,
    battle_h: 336946.26,
    exp_m: 1684.9998,
    exp_h: 101099.988,
    dust_m: 0.8922,
    dust_h: 53.532,
    core_h: 77.6214
  },
  {
    level: 392,
    credit_m: 525.2052,
    credit_h: 31512.312,
    battle_m: 5626.194,
    battle_h: 337571.64,
    exp_m: 1689.9999,
    exp_h: 101399.994,
    dust_m: 0.8936,
    dust_h: 53.616,
    core_h: 77.7432
  },
  {
    level: 393,
    credit_m: 525.9975,
    credit_h: 31559.85,
    battle_m: 5636.612,
    battle_h: 338196.72,
    exp_m: 1695,
    exp_h: 101700,
    dust_m: 0.895,
    dust_h: 53.7,
    core_h: 77.865
  },
  {
    level: 394,
    credit_m: 526.7898,
    credit_h: 31607.388,
    battle_m: 5647.025,
    battle_h: 338821.5,
    exp_m: 1699.9998,
    exp_h: 101999.988,
    dust_m: 0.8963,
    dust_h: 53.778,
    core_h: 77.9781
  },
  {
    level: 395,
    credit_m: 527.5818,
    credit_h: 31654.908,
    battle_m: 5657.432,
    battle_h: 339445.92,
    exp_m: 1704.9999,
    exp_h: 102299.994,
    dust_m: 0.8977,
    dust_h: 53.862,
    core_h: 78.0999
  },
  {
    level: 396,
    credit_m: 528.3738,
    credit_h: 31702.428,
    battle_m: 5667.835,
    battle_h: 340070.1,
    exp_m: 1710,
    exp_h: 102600,
    dust_m: 0.8991,
    dust_h: 53.946,
    core_h: 78.2217
  },
  {
    level: 397,
    credit_m: 529.1655,
    credit_h: 31749.93,
    battle_m: 5678.232,
    battle_h: 340693.92,
    exp_m: 1714.9998,
    exp_h: 102899.988,
    dust_m: 0.9005,
    dust_h: 54.03,
    core_h: 78.3435
  },
  {
    level: 398,
    credit_m: 529.9569,
    credit_h: 31797.414,
    battle_m: 5688.623,
    battle_h: 341317.38,
    exp_m: 1719.9999,
    exp_h: 103199.994,
    dust_m: 0.9019,
    dust_h: 54.114,
    core_h: 78.4653
  },
  {
    level: 399,
    credit_m: 530.7483,
    credit_h: 31844.898,
    battle_m: 5699.01,
    battle_h: 341940.6,
    exp_m: 1723.9998,
    exp_h: 103439.988,
    dust_m: 0.9032,
    dust_h: 54.192,
    core_h: 78.5784
  },
  {
    level: 400,
    credit_m: 531.5391,
    credit_h: 31892.346,
    battle_m: 5709.392,
    battle_h: 342563.52,
    exp_m: 1728.9999,
    exp_h: 103739.994,
    dust_m: 0.9046,
    dust_h: 54.276,
    core_h: 78.7002
  }
];
